import SemrTextArea from '@semcore/ui/textarea';

interface Props {
    onChange: (value: string) => void;
    value: string;
    cols?: number;
    rows?: number;
    disabled?: boolean;
    id?: string;
    name?: string;
    className?: string;
}

export function Textarea({
    onChange,
    value,
    cols = 30,
    rows = 10,
    disabled = false,
    id = '',
    name = '',
    className = '',
}: Props) {
    return (
        <SemrTextArea
            className={className}
            id={id}
            name={name}
            onChange={onChange}
            value={value}
            cols={cols}
            rows={rows}
            disabled={disabled}
        />
    );
}
