import './i18n';
import './util/sentry';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import Bootstrap from './bootstrap';
import * as serviceWorker from './serviceWorker';
import { initAppCenterSDK } from '@semcore/app-center-js-sdk';

const MAX_RETRIES = 50;
let retries = 0;

initAppCenterSDK();

injectSemrushSDK()
    .then(bootstrap)
    .catch(error => {
        console.error('Error initializing SM', error);
    });

async function injectSemrushSDK() {
    do {
        if (retries > 0) {
            await new Promise(resolve => setTimeout(resolve, 200));
        }

        if ('SM' in window && window.SM?.['init']) {
            try {
                // @ts-ignore
                await window.SM.init();
                return;
            } catch (error) {
                console.error('Error initializing SM', error);
            }
        }

        ++retries;
    } while (retries++ < MAX_RETRIES);

    throw new Error('Failed to initialize SM');
}

function bootstrap() {
    const container = document.getElementById('root');
    const root = createRoot(container);

    root.render(
        <React.StrictMode>
            <Bootstrap />
        </React.StrictMode>,
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}
