import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from './button';
import UpgradeCta from '../asset/upgrade-cta.svg';
import { useUser } from '../core';
import { UserPlan } from '../types';

const Main = styled.div`
    display: flex;
    height: 134px;
    padding: var(--baseline);
    background: linear-gradient(0deg, rgba(43, 148, 225, 0.15), rgba(43, 148, 225, 0.15)), #ffffff;
    border: 1px solid rgba(43, 148, 225, 0.25);
    border-radius: calc(var(--baseline) / 2);
`;

const Info = styled.p`
    font-size: 12px;
    margin-bottom: calc(var(--baseline) * 1.5);
`;

const Content = styled.div`
    margin-left: calc(var(--baseline) * 5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const Title = styled.h5`
    margin-bottom: calc(var(--baseline) / 2);
`;

export function UpgradeBanner() {
    let { t } = useTranslation();
    const { data: user } = useUser();

    if (user?.billing_status !== UserPlan.FREE) {
        return null;
    }

    return (
        <Main>
            <img src={UpgradeCta} alt='upgrade cta' />
            <Content>
                <Title>{t('upgrade.title')}</Title>
                <Info>{t('upgrade.info')}</Info>
                <Button theme='success' use='primary'>
                    {t('upgrade.label')}
                </Button>
            </Content>
        </Main>
    );
}
