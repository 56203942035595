import React from 'react';
import styled from 'styled-components';
import { Spin } from '../../semcore';

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
`;

export const Loading = () => (
    <LoadingWrapper>
        <Spin size='xxl' />
    </LoadingWrapper>
);
