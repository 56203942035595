import { createContext, useContext } from 'react';
import { getToken } from '../user';
import { ProcessEnv } from '../types';
import type { FirebaseOptions } from 'firebase/app';

export interface ISegmentConfig {
    apiKey: string;
}

export interface UserConfig {
    error: {
        global: string;
    };
}

export interface IConfig {
    nodeEnv: string;
    appId: string;
    backendBaseUrl: string;
    apiKey: string;
    semrushJwt: string;
    segment: ISegmentConfig;
    firebase: FirebaseOptions;
    error: {
        global: string;
    };
}

export function getConfig(env: ProcessEnv, conf: UserConfig): IConfig {
    if (!env.REACT_APP_ID) {
        throw new Error('No APP_ID provided.');
    }

    if (!env.REACT_APP_API_KEY) {
        throw new Error('No API key provided.');
    }

    if (!env.REACT_APP_BACKEND_URL) {
        throw new Error('Cannot connect to backend. No backend URL provided.');
    }

    if (env.NODE_ENV !== 'development' && !env.REACT_APP_SEGMENT_API_KEY) {
        throw new Error('No Segment API key provided.');
    }

    const token = getToken(window);

    const semrushJwt = token ? token : env.REACT_APP_JWT_DEV;

    if (!semrushJwt && window.name !== 'semrush_iframe') {
        throw new Error('Cannot find user token, No JWT provided.');
    }

    const firebaseConfig = getFirebaseConfig(env);

    return {
        nodeEnv: env.NODE_ENV,
        appId: env.REACT_APP_ID,
        semrushJwt: semrushJwt ?? '',
        backendBaseUrl: env.REACT_APP_BACKEND_URL,
        apiKey: env.REACT_APP_API_KEY,
        segment: {
            apiKey: env.REACT_APP_SEGMENT_API_KEY,
        },
        firebase: firebaseConfig,
        ...conf,
    };
}

export const Config = createContext<IConfig>({
    nodeEnv: '',
    appId: '',
    backendBaseUrl: '',
    apiKey: '',
    semrushJwt: '',
    segment: {
        apiKey: '',
    },
    firebase: {
        apiKey: '',
        authDomain: '',
        projectId: '',
        appId: '',
    },
    error: {
        global: '',
    },
});

export function useConfig() {
    return useContext(Config);
}


const getFirebaseConfig = (env: ProcessEnv): FirebaseOptions => {
    if (!env.REACT_APP_FIREBASE_API_KEY) {
        throw new Error('No Firebase API key provided.');
    }

    if (!env.REACT_APP_FIREBASE_AUTH_DOMAIN) {
        throw new Error('No Firebase Auth Domain provided.');
    }

    if (!env.REACT_APP_FIREBASE_PROJECT_ID) {
        throw new Error('No Firebase Project ID provided.');
    }

    if (!env.REACT_APP_FIREBASE_APP_ID) {
        throw new Error('No Firebase App ID provided.');
    }

    return {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
      };
};
