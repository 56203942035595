const translation = {
    upgrade: {
        title: 'Upgrade for more insights.',
        info: 'Get results for 190 more keywords.',
        label: 'Upgrade',
    },

    keywords: {
        title: 'Keyword Rank Tracking.',
        tab: 'Keywords',
        keyword: 'Keyword',
        current_rank: 'Rank',
        rank_change: 'Change',
        rank_change_tool_tip: 'Rank change compared to previous period',
        search_volume_tooltip:
            'Average monthly search volume on Google, YouTube and search partners',
        result: 'Video Result',
        type: 'Type',
        search_volume: 'Volume',
        video: 'Video',
        view_all: 'View All Keywords',
        top: 'Top Ranking Keywords',
        limit: 'Tracked Keywords/Limit',
        delete_multi: 'Delete Keywords',
        youtube_results: 'YouTube results',
        delete_multi_description:
            'Are you sure you want to delete these keywords? All associated data will be lost.',

        empty: {
            title: 'Your keyword list is empty!',
            description1: 'There are many ways to add keywords to your list.',
            description2: 'Learn how to',
            link1: 'import keywords from YouTube',
            link2: 'Semrush Keyword Manager',
        },

        line: 'Line',
        error: 'Looks like we’re having trouble adding keywords.',
        error_limit:
            'The entered keyword is longer than the allowed number of 255 characters. Please enter a shorter keyword.',
    },

    overview: {
        title: 'YouTube Rank Tracking',
        tab: 'Overview',
    },

    app: {
        select_channel: '-- select channel --',
        add_channel: 'Add Channel',
        add_keywords: 'Add Keywords',
        cancel: 'Cancel',
        delete: 'Delete',
        remove: 'Remove',
        loading: 'loading',
        prev: 'Prev',
        next: 'Next',
        global: 'Global',
        location: 'Location',
        search_engine: 'Search Engine: YouTube',
        keywords: 'Keywords',
        keywords_available: 'Keywords available',
        updated_at: 'Updated: Last 7 Days',
        source: 'Source',
        youtube: 'YouTube',
        back: 'Back',
        imprint: 'Imprint',
        privacy_policy: 'Privacy Policy',
        export: 'Export',
        upgradePlan: 'Buy more keywords',
        upgradePlanButton: 'Buy more keywords',
    },

    format: {
        csv: {
            short: 'CSV',
            long: '(CSV) Comma Separated Values',
        },

        xls: {
            short: 'XLS',
            long: '(XLS) Microsoft Excel Format',
        },
    },

    onboarding: {
        add_channel: {
            title: 'Add your YouTube Channel',
            description: 'YouTube Channel URL',
            invalid: `Your YouTube Channel URL doesn’t look right. Please make sure to enter the URL in the format like: 
            https://www.youtube.com/user/... or
            https://www.youtube.com/channel/... or
            https://www.youtube.com/c/... or
            https://www.youtube.com/@... or
            https://www.youtube.com/...
            `,
            error_title: 'Something went wrong.',
            error_info:
                'Looks like we’re having trouble getting the data of your YouTube Channel.',
        },

        keywords: {
            title: 'Import Your Keywords',
            enter_keywords: 'Enter up to {{limit}} keywords. Add one per line.',
            import_keywords: 'Import Keywords',
            youtube_studio_tab: 'Import from YouTube Studio',
            semrush_tab: 'Import from Semrush',
        },
        import_youtube: {
            how_to: 'How to import from YouTube Studio',
            link: 'ANALYTICS',
            step1: 'Sign in to YOUTUBE and go to',
            step2: 'Select REACH',
            step3: 'Scroll to the TRAFFIC SOURCE: YOUTUBE SEARCH window at the bottom right and click SEE MORE',
            step4: 'Set time period LIFETIME',
            step5: 'Click EXPORT CURRENT VIEW',
            step6: 'Open the data and copy/paste the first {{limit}} keywords to the Video Rank Tracker app in Semrush',

            open_yt_studio: 'Open YouTube Studio',
        },
        import_semrush: {
            how_to: 'How to import from Semrush Keyword Manager',
            link: ' Semrush Keyword Manager',
            step1: 'Go to the ',
            step2: 'Select the keyword list containing your most relevant keywords',
            step3: 'Click the EXPORT button at the top right',
            step4: 'Select ALL and XLSX',
            step5: 'Open the file and copy/paste the first {{limit}} keywords to the Video Rank Tracker app in Semrush',

            open_SR_manager: 'Go to Semrush Keyword Manager ',
        },

        loading: {
            subheadline:
                'Your YouTube rankings may take a few minutes to load.',
            video: 'While waiting check out the basics of YouTube SEO:',
            imported: 'keywords imported',
        },
    },

    delete_channel: {
        title: 'Remove YouTube Channel from Tracking',
        description:
            'You are about to permanently delete all data related to the YouTube channel {{name}}, together ' +
            'with all keywords, YouTube ranking history, reports and other associated data. This action cannot be ' +
            'undone.',
        button: 'I understand, remove it.',
        back: 'Cancel',
    },

    error: {
        channel_not_found: 'No channel id found for url {{url}}',
        generic_title: 'Something went wrong.',
    },

    empty_channel: {
        header: 'YouTube Rank Tracking',
        title: 'Set up your Video Rank Tracker!',
        description_line1:
            'Video Rank Tracker allows you to get daily updates on',
        description_line2: 'positions and other benefits we could list here.',
        button: 'Set up Video Rank Tracker',
    },

    keyword_distribution: {
        top3: 'Top 3',
        top10: 'Top 10',
        top20: 'Top 20',
        noRank: 'No Rank',
        title: 'Keyword Distribution',
        info:
            'The keyword distribution represents the ratio of your top 3, top 10, top 20 as well as non-ranking ' +
            'keywords and how positions changed compared to the previous period.',
    },

    avg_position: {
        title: 'Average Rank',
        info: 'Adjusted average YouTube rank for all keywords in your list.',
    },

    keyword_rank: {
        title: 'Keywords Rank Up/Down',
        info: 'Count of keywords whose rank improved and count of keywords whose rank decreased or stayed the same.',
    },

    limit: {
        info:
            'Your account is limited to a certain number of keywords you monitor. The limit can be spread over ' +
            'multiple YouTube channels.',
        title: 'How are keyword limits calculated?',
        error_info:
            'This can also mean, you are tracking keywords on other channels already.',
        error_reason:
            "You have exceeded your account's maximum keyword limit. Please remove keywords.",
    },

    serp: {
        title: 'Top 20 YouTube results for “{{search}}”',
        title_note:
            'Please note that the ranks in this preview may differ from the ranks in the table since results are generated live.',
        no_data: 'We have no data to show',
        no_data_note: 'We found no videos on YouTube ranking for this keyword',
        error: 'Something went wrong',
        error_note: 'Try closing the popup and reload the page to fix it',
        you: 'You',
        published: 'Published',
        columns: {
            rank: 'Rank',
            video: 'Video',
            channel: 'Channel',
            views: 'Views',
        },
    },
};

const translationWrapper = { translation };

export default translationWrapper;
