import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from './button';
import { useAddAction, useUser } from '../core';
import { ActionPosition } from '../types';

interface Props {
    onDelete: (channelId: string | null | undefined) => void;
    onCancel: () => void;
    channel: string | null | undefined;
}

export default function DeleteChannel({ onDelete, onCancel, channel }: Props) {
    const { t } = useTranslation();
    const { data: user } = useUser();
    const addAction = useAddAction();

    const selectedChannel = useMemo(() => {
        if (!user) {
            return null;
        }

        return user.channels.find((c) => c.id === channel);
    }, [user, channel]);

    function handleDelete() {
        addAction({ action: 'Clicked Delete Channel Button', data: { position: ActionPosition.DASHBOARD } });
        selectedChannel && onDelete(selectedChannel.id);
    }

    if (!selectedChannel) {
        return null;
    }

    return (
        <form>
            <h2>{t('delete_channel.title', { name: selectedChannel?.title })}</h2>
            <p>{t('delete_channel.description', { name: selectedChannel?.title })}</p>
            <Footer>
                <Button theme='danger' use='primary' onClick={handleDelete} size='m'>
                    {t('delete_channel.button').toString()}
                </Button>
                <Button onClick={() => onCancel()} theme='invert' use='primary' size='m'>
                    {t('delete_channel.back').toString()}
                </Button>
            </Footer>
        </form>
    );
}

const Footer = styled.footer`
    display: flex;
    justify-content: flex-start;

    button {
        margin-right: var(--baseline);
    }
`;
