export const CHANNEL_URL_PREFIX = ['/c/', '/channel/', '/user/', '/@', '/'];

export const YOUTUBE_DOMAINS = ['youtube.com', 'youtu.be'];

export function hasPrefix(url: string): boolean {
    return CHANNEL_URL_PREFIX.some((prefix) => url.startsWith(prefix));
}

export function getDomainName(host: string): string {
    const parts = host.split('.');
    const extension = parts[parts.length - 1];
    const name = parts[parts.length - 2];
    return `${name}.${extension}`;
}

export function isYTURL(url: string): boolean {
    if (url === '') {
        return true;
    }

    if (!url.startsWith('http')) {
        return false;
    }

    const { pathname, host } = getURL(url);
    const domain = getDomainName(host);

    if (!YOUTUBE_DOMAINS.some((d) => domain === d)) {
        return false;
    }

    if (!hasPrefix(pathname)) {
        return false;
    }

    return true;
}

function getURL(url: string) {
    try {
        return new window.URL(url);
    } catch (err) {
        return { pathname: '', host: '' };
    }
}
