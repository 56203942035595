import React from 'react';

export default class DashboardErrorBoundary extends React.Component<{ children }, { hasError: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: any, info: any) {
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return <h1>Error occurred! Please Reload the page</h1>;
        }
        return this.props.children;
    }
}
