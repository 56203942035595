import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Charts } from '../component/chart';
import AvgPosition from './component/avg-position';
import KeywordDistribution from './component/keyword-distribution';
import KeywordRank from './component/keyword-rank';
import { TopKeywords } from './component/top-keywords';
import { Link } from 'react-router-dom';
import { useSelectedChannel } from '../util/channel';
import { UpgradeBanner } from '../component/upgrade-banner';
import { useTranslation } from 'react-i18next';
import { DateRange } from '../component/date-range';
import Serp from '../component/serp';
import { Button } from '../semcore';
import { getSemrushSDK, Methods, useAddAction } from '../core';
import { ActionPosition } from '../types';

export function Overview() {
    const { t } = useTranslation();
    const channelId = useSelectedChannel();
    const [showSerp, setShowSerp] = useState(false);
    const [serpKeyword, setSerpKeyword] = useState('');
    const addAction = useAddAction();

    const semrush = getSemrushSDK();

    useEffect(() => {
        addAction({ action: 'Viewed Overview', data: { position: ActionPosition.DASHBOARD } });
    }, [addAction]);

    const handleUpdateClick = async () => {
        addAction({ action: 'Clicked buy more keywords button', data: { position: ActionPosition.DASHBOARD } });
        await semrush.client<[], void>(Methods.RequestInAppPurchase);
    };

    return (
        <Fragment>
            <Charts>
                <AvgPosition />
                <KeywordDistribution />
                <KeywordRank />
            </Charts>
            <Bottom>
                <Header>
                    <section>
                        <Title>{t('keywords.top')}</Title>
                        <DateRange />
                    </section>
                    <HeaderCTA>
                        <Button theme='success' use='primary' onClick={handleUpdateClick}>
                            {t('app.upgradePlanButton')}
                        </Button>
                    </HeaderCTA>
                </Header>
                <Serp open={showSerp} search={serpKeyword} close={() => setShowSerp(false)} />
                <TableWrapper>
                    <TopKeywords
                        setSerpKeyword={setSerpKeyword}
                        showSerp={() => {
                            addAction({ action: 'Clicked show serp button', data: { position: ActionPosition.DASHBOARD } });
                            setShowSerp(true);
                        }}
                    />
                </TableWrapper>
                <StyledLink to={`/dashboard/${channelId}/keywords`}>
                    <Button use='primary'>{t('keywords.view_all')}</Button>
                </StyledLink>
            </Bottom>

            <UpgradeBanner />
        </Fragment>
    );
}

const Bottom = styled.section`
    display: flex;
    flex-direction: column;
    margin-bottom: calc(var(--baseline) * 5);
`;

const StyledLink = styled(Link)`
    display: block;
`;

const TableWrapper = styled.div`
    margin-bottom: calc(var(--baseline) * 2);
`;

const Title = styled.h4`
    margin-bottom: var(--baseline);
`;

const Header = styled.header`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

const HeaderCTA = styled.section`
    margin-top: 9px;
`;
