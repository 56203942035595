import React from 'react';
import { NA } from './na';
import { getShortNumberFormat } from '../util/numbers';

type Props = {
    value?: string | number | null;
    decimals?: number;
};

export function SearchVolume({ value = null, decimals = 1 }: Props) {
    if (!value) {
        return <NA>n/a</NA>;
    }

    if (isNaN(+value)) {
        return <>{value}</>;
    }

    return <>{getShortNumberFormat(value as number, decimals)}</>;
}
