import { useEffect } from 'react';
import styled from 'styled-components';
import { useProgress } from '@biotic-ui/stepper';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSyncStatus } from '../core';
import { IChannelDto } from '../types';

interface LoadingProps {
    channel: IChannelDto;
    keywords: Array<string>;
    onSuccess: () => void;
}

export function Loading({ channel, keywords, onSuccess }: LoadingProps) {
    const { t } = useTranslation();
    const { data: progress } = useSyncStatus(channel.id || '');

    useEffect(() => {
        if (!progress) {
            return;
        }
        const isReady = progress.synced === progress.keywords;
        if (isReady) {
            onSuccess();
        }
    }, [progress, onSuccess]);

    return (
        <Wrapper>
            <Header>
                <Info>{t('onboarding.loading.subheadline')}</Info>
                <Small>
                    <Label>
                        <span>
                            {progress?.synced ? progress.synced : 0}/{keywords.length}
                        </span>
                        {t('onboarding.loading.imported')}
                    </Label>
                </Small>
            </Header>

            <Video>
                <h4>{t('onboarding.loading.video')}</h4>
                <iframe
                    title={t('onboarding.loading.video').toString()}
                    width="560"
                    height="315"
                    src="https://www.youtube-nocookie.com/embed/0E0RrG7A0CU"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </Video>
        </Wrapper>
    );
}

export default function LoadingContainer() {
    const { data } = useProgress();

    const channel = data[0];
    const keywords = data[1];

    const navigate = useNavigate();

    function redirect() {
        navigate(`/dashboard/${channel.id}/overview`);
    }

    return <Loading channel={channel} keywords={keywords} onSuccess={redirect} />;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Info = styled.p`
    margin-bottom: calc(var(--baseline) * 2.5);
    margin-top: calc(var(--baseline) * 2.5);
`;

const Small = styled.small`
    display: block;
`;

const Label = styled.p`
    margin-bottom: 0;
    text-align: center;

    span {
        display: inline-block;
        margin-right: var(--baseline);
    }
`;

const Header = styled.section`
    margin-bottom: calc(var(--baseline) * 3);
    text-align: center;
`;

const Video = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
