import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, DropdownMenu, FileDownload, Modal, Pagination, Progress, Trash } from '../semcore';
import { getChannel, useSelectedChannel } from '../util/channel';
import { useNavigate, useLocation } from 'react-router-dom';
import AddKeywords from '../component/add-keywords';
import DeleteKeywords from '../component/delete-keywords';
import { useTranslation } from 'react-i18next';
import { KeywordsTable, Row } from '../component/keywords';
import { trimKeywords } from '../util/keyword';
import { UpgradeBanner } from '../component/upgrade-banner';
import { DateRange } from '../component/date-range';
import { exportDate, useFormatDates, useSubDays, useTomorrow, useWeekBefore } from '../util/date';
import { downloadFile, ExportExt, ExportType, formatCSV, formatXLS } from '../util/export';
import Serp from '../component/serp';
import { useAddAction, useAddKeywords, useDeleteKeywords, useKeywordLimit, useKeywords, useUser } from '../core';
import { ActionPosition, RankingsResultDto } from '../types';

interface KeywordsProps {
    selectedChannel: string | null;
    setSerpKeyword: Dispatch<SetStateAction<string>>;
    showSerp: () => void;
    rankings: Array<RankingsResultDto> | null;
    isLoadingRankings: boolean;
    onAdd: (k: Array<string>) => Promise<any>;
    onClose: (
        trigger: 'onOutsideClick' | 'onCloseClick' | 'onEscape',
        e?: React.MouseEvent | React.KeyboardEvent,
    ) => void;
    dialog: boolean;
    onDialogChange: (c: boolean) => void;
    onDeleteKeywordsDialogChange: (c: boolean) => void;
    deleteKeywordsDialog: boolean;
    selected: Array<number>;
    onSelect: (s: number) => void;
    onSelectAll: (s: Array<number>) => void;
    onSort: (s: string) => void;
    sort: keyof Row;
    order: 'desc' | 'asc';
    page: number;
    pages: number;
    onSetPage: (p: number) => void;
    onDelete: () => void;
    onCancel: () => void;
    onError: () => void;
    onExport: (type: ExportType) => void;
}

export function Keywords({
    selectedChannel,
    setSerpKeyword,
    showSerp,
    rankings,
    isLoadingRankings,
    onAdd,
    onClose,
    dialog,
    onDialogChange,
    onDeleteKeywordsDialogChange,
    deleteKeywordsDialog,
    selected,
    onSelect,
    onSelectAll,
    onSort,
    sort,
    order,
    page,
    pages,
    onSetPage,
    onDelete,
    onCancel,
    onError,
    onExport,
}: KeywordsProps) {
    const { t } = useTranslation();
    const keywords = useKeywordLimit();
    const addAction = useAddAction();

    useEffect(() => {
        addAction({ action: 'Viewed Keywords', data: { position: ActionPosition.DASHBOARD } });
    }, [addAction]);

    function handleSelectAll() {
        if (selected.length > 0) {
            onSelectAll([]);
        } else {
            rankings && onSelectAll(rankings.map((ranking) => ranking.id));
        }
    }

    function handlePageChange(nextPage: number) {
        if (selected.length > 0) {
            onSelectAll([]);
        }

        onSetPage(nextPage);
    }

    let data: Array<Row> = !rankings ? []
        : rankings.map((ranking) => ({
            id: ranking.id,
            selected: selected.includes(ranking.id),
            keyword: ranking.value,
            preview: ranking.value,
            rank: ranking?.rank?.current,
            change: ranking?.rank?.change,
            videoResult: ranking?.currentResult?.entity,
            searchVolume: ranking?.searchVolume,
        }));

    return (
        <React.Fragment>
            <Modal open={dialog} onClose={onClose}>
                <h3>Add keywords</h3>
                <AddKeywords onAdd={onAdd} />
            </Modal>
            <Modal open={deleteKeywordsDialog} onClose={onCancel}>
                <DeleteKeywords onDelete={onDelete} onCancel={onCancel} onError={onError} />
            </Modal>
            <Header>
                <div>
                    <h4>Keywords</h4>
                    <DateRange />
                </div>
                <HeaderContent>
                    <ProgressContainer>
                        <ProgressKeywords>
                            <span>Keywords</span>
                            <span>
                                {keywords.total}
                                <Limit>/{keywords.limit}</Limit>
                            </span>
                        </ProgressKeywords>
                        <Progress theme='#2B94E1' step={keywords.total} steps={keywords.limit} />
                    </ProgressContainer>

                    {selected.length > 0 && (
                        <DeleteIcon>
                            <Button onClick={() => onDeleteKeywordsDialogChange(true)}>
                                <Trash />
                            </Button>
                        </DeleteIcon>
                    )}

                    <Button
                        theme='success'
                        use='primary'
                        disabled={keywords.total >= keywords.limit}
                        onClick={() => onDialogChange(!dialog)}
                    >
                        {t('app.add_keywords')}
                    </Button>

                    <DropdownMenu placement='bottom-end'>
                        <DropdownMenu.Trigger>
                            <ExportButton>
                                <FileDownload /> {t('app.export')}
                            </ExportButton>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Popper>
                            <DropdownMenu.List>
                                <DropdownMenu.Item onClick={() => onExport(ExportType.CSV)}>
                                    {t('format.csv.long').toString()}
                                </DropdownMenu.Item>
                                <DropdownMenu.Item onClick={() => onExport(ExportType.XLS)}>
                                    {t('format.xls.long').toString()}
                                </DropdownMenu.Item>
                            </DropdownMenu.List>
                        </DropdownMenu.Popper>
                    </DropdownMenu>
                </HeaderContent>
            </Header>
            <TableWrapper>
                <KeywordsTable
                    onAdd={() => onDialogChange(true)}
                    data={data}
                    isLoading={isLoadingRankings}
                    selected={selected}
                    onSelectAll={handleSelectAll}
                    onSelect={onSelect}
                    onSort={onSort}
                    setSerpKeyword={setSerpKeyword}
                    showSerp={showSerp}
                    sort={sort}
                    order={order}
                />
            </TableWrapper>
            <PaginationWrapper>
                <Pagination currentPage={page} onCurrentPageChange={handlePageChange} totalPages={pages} />
            </PaginationWrapper>
            <UpgradeBanner />
        </React.Fragment>
    );
}

const PAGE_SIZE = 10;
export default function KeywordsContainer() {
    const [showSerp, setShowSerp] = useState(false);
    const [serpKeyword, setSerpKeyword] = useState('');
    const addAction = useAddAction();
    const selectedChannel = useSelectedChannel();
    const { data: user } = useUser();

    const tomorrow = useTomorrow();
    const comparison = useSubDays(tomorrow, 7);
    const lastWeek = useWeekBefore(comparison);
    const currentWeek = useWeekBefore(tomorrow);
    const ranges = useFormatDates([...[lastWeek, comparison], ...[currentWeek, tomorrow]]);

    const [refresh, setRefresh] = useState(false);
    const [sort, setSort] = useState<keyof Row>('id');
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');

    const { data: rankings, isLoading: isLoadingRankings } = useKeywords(selectedChannel, ranges, refresh, sort, order);
    const addKeywords = useAddKeywords(selectedChannel, ranges, sort, order);
    const handleDelete = useDeleteKeywords(selectedChannel, ranges, sort, order);

    useEffect(() => {
        if (!rankings) {
            return;
        }

        const pollRank = rankings.some((keyword) => {
            return keyword.rank === undefined;
        });

        setRefresh(pollRank);
    }, [rankings, setRefresh]);

    const [page, setPage] = useState(1);

    const navigate = useNavigate();
    const location = useLocation();
    const [dialog, setDialog] = useState<boolean>(() => {
        const params = new URLSearchParams(location.search);
        return params.has('add');
    });

    const [deleteKeywordsDialog, setDeleteKeywordsDialog] = useState(false);
    const [, setDeleteKeywordError] = useState(false);

    function handleSort(s: keyof Row) {
        setSort(s);
        order === 'asc' ? setOrder('desc') : setOrder('asc');
    }

    async function handleAdd(keys: Array<string>) {
        const keywords: Array<string> = trimKeywords(keys);
        await addKeywords({ keywords });
        setDialog(false);
        setPage(1);
    }

    const [selected, setSelected] = useState<Array<number>>([]);

    function handleSelect(id: number) {
        setSelected((currentSelected) => {
            if (currentSelected.includes(id)) {
                return currentSelected.filter((_id) => _id !== id);
            } else {
                return [...currentSelected, id];
            }
        });
    }

    function handleSelectAll(ids: Array<number>) {
        setSelected(ids);
    }

    async function handleDeleteAll() {
        try {
            await handleDelete({ keywordIds: selected });
            setDeleteKeywordsDialog(false);
            setSelected([]);
        } catch (error) {
            alert('ERROR OCCURRED WHILE DELETING');
            console.log({ error });
        }
    }

    function cancelDelete() {
        setDeleteKeywordsDialog(false);
    }

    function handleError() {
        setDeleteKeywordError(true);
    }

    function handleClose() {
        setDialog(false);
        const params = new URLSearchParams(location.search);
        params.delete('add');
        navigate({
            ...location,
            search: `?${params.toString()}`,
        });
    }

    const currentRankings = useMemo(() => {
        if (!rankings) {
            return null;
        }
        const currentPage = page - 1;
        const start = currentPage * PAGE_SIZE;
        const end = (currentPage + 1) * PAGE_SIZE;
        return rankings.slice(start, end);
    }, [rankings, page]);

    useEffect(() => {
        setSelected([]);
        setDeleteKeywordsDialog(false);
        setPage(1);
    }, [selectedChannel]);

    function handleExport(type: ExportType) {
        const config = {
            columns: () => [
                { title: 'Keyword', prop: 'keyword' },
                { title: 'Result', prop: 'result' },
                { title: 'Type', prop: 'type' },
                { title: 'Topic Search Volume', prop: 'searchVolume' },
                { title: 'Rank', prop: 'current' },
                { title: 'Change', prop: 'change' },
            ],
        };

        const items = (rankings ?? []).map((item) => {
            const id = item.currentResult?.entity?.id;
            const result = !id ? '' : `https://www.youtube.com/watch?v=${id}`;

            return {
                type: 'Video',
                searchVolume: item.searchVolume,
                current: item.rank?.current,
                change: item.rank?.change,
                result,
                keyword: item.value,
            };
        });

        const file = type === ExportType.CSV ? formatCSV(items, config) : formatXLS(items, 'Worksheet', config);
        const ext = type === ExportType.CSV ? ExportExt.CSV : ExportExt.XLS;
        const channel = getChannel(selectedChannel, user)();
        const channelName = channel ? `_${formatTitle(channel.channel.title ?? '')}` : '';
        const name = `${exportDate()}${channelName}_video_rank_tracker_export`;

        addAction({ action: 'Export', data: { type: ext } });
        downloadFile(file, `${name}.${ext}`);
    }

    return (
        <>
            <Serp open={showSerp} search={serpKeyword} close={() => setShowSerp(false)} />
            <Keywords
                selectedChannel={selectedChannel}
                setSerpKeyword={setSerpKeyword}
                showSerp={() => {
                    addAction({ action: 'Clicked show serp button', data: { position: ActionPosition.KEYWORDS } });
                    setShowSerp(true);
                }}
                rankings={currentRankings}
                isLoadingRankings={isLoadingRankings}
                onAdd={handleAdd}
                onClose={handleClose}
                dialog={dialog}
                deleteKeywordsDialog={deleteKeywordsDialog}
                onDialogChange={setDialog}
                onDeleteKeywordsDialogChange={setDeleteKeywordsDialog}
                selected={selected}
                onSelect={handleSelect}
                onSelectAll={handleSelectAll}
                onSort={handleSort}
                sort={sort}
                order={order}
                page={page}
                pages={rankings ? Math.ceil(rankings.length / PAGE_SIZE) : 0}
                onSetPage={setPage}
                onDelete={handleDeleteAll}
                onCancel={cancelDelete}
                onError={handleError}
                onExport={handleExport}
            />
        </>
    );
}

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: calc(var(--baseline) * 2);

    h4 {
        margin-bottom: calc(var(--baseline) * 0.5);
    }
`;

const HeaderContent = styled.section`
    display: flex;
    align-items: center;
`;

const ProgressContainer = styled.div`
    flex-grow: 0;
    margin-right: calc(var(--baseline) * 2);
`;

const DeleteIcon = styled.div`
    margin-right: calc(var(--baseline) * 2);
`;

const ProgressKeywords = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: calc(var(--baseline) / 2);
    width: 140px;
`;

const TableWrapper = styled.div`
    margin-bottom: calc(var(--baseline) * 2);
`;

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: calc(var(--baseline) * 5);
`;

const Limit = styled.span`
    color: var(--gray60);
`;

const ExportButton = styled(Button)`
    margin-left: var(--baseline);
`;

function formatTitle(title: string) {
    return title.split(' ').join('_');
}
