import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from './button';
import { useKeywordLimit } from '../core';

export default function ImportSemrush() {
    const { limit } = useKeywordLimit();
    const { t } = useTranslation();

    return (
        <Main>
            <VideoPlaceholder>
                <iframe
                    title="semrush"
                    src="https://www.youtube.com/embed/p0L-GKEwfbQ"
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </VideoPlaceholder>
            <Title>{t('onboarding.import_semrush.how_to')}</Title>
            <Steps>
                <StepsList>
                    <ListItems>
                        <span>{t('onboarding.import_semrush.step1')} </span>
                        <SemrLink
                            href="https://www.semrush.com/analytics/keywordmanager/lists"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('onboarding.import_semrush.link')}
                        </SemrLink>
                    </ListItems>
                    <ListItems>{t('onboarding.import_semrush.step2')}</ListItems>
                    <ListItems>{t('onboarding.import_semrush.step3')}</ListItems>
                    <ListItems>{t('onboarding.import_semrush.step4')}</ListItems>
                    <ListItems>{t('onboarding.import_semrush.step5', { limit })}</ListItems>
                </StepsList>
            </Steps>
            <Button size="m" use="secondary">
                <Link
                    href="https://www.semrush.com/analytics/keywordmanager/lists"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t('onboarding.import_semrush.open_SR_manager')}
                </Link>
            </Button>
        </Main>
    );
}

const Main = styled.div`
    Button a {
        color: #757575;
        text-decoration: none;
    }
`;
const Link = styled.a`
    color: #757575;
    text-decoration: none;
`;

const SemrLink = styled.a`
    padding-left: calc(var(--baseline) * 0.5);
`;

const Steps = styled.div`
    font-size: 14px;
    margin: calc(var(--baseline) * 2) 0;
`;

const VideoPlaceholder = styled.div`
    margin: calc(var(--baseline) * 2) 0;

    img {
        max-width: 100%;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    iframe {
        width: 100%;
        height: 200px;
    }
`;

const StepsList = styled.ol`
    list-style: none;
    padding-left: calc(var(--baseline));
`;

const ListItems = styled.li`
    margin-bottom: calc(var(--baseline));
    display: inline-flex;
    counter-increment: item;
    ::before {
        content: counter(item);
        color: #757575;
        padding-right: calc(var(--baseline));
    }
`;

const Title = styled.h5`
    font-size: 1em;
`;
