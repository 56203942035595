import React from 'react';
import styled from 'styled-components';
import { ArrowDown, ArrowUp, Spin } from '../semcore';
import { NA } from './na';

type Props = {
    value?: number | null;
};

export function RankChange({ value }: Props) {
    // still loading
    if (value === undefined) {
        return <Loading />;
    }

    // rank is equal to last week || no rank
    if (value === 0 || value === null) {
        return (
            <Wrapper style={{ justifyContent: 'center' }}>
                <NA>0</NA>
            </Wrapper>
        );
    }

    // rank is going down
    // e.g: previous = 10; current = 15
    // 10 - 15 = -5 => going down by 5
    if (value < 0) {
        return (
            <Wrapper>
                <ArrowDown color='var(--red)' /> <RankNumber>{Math.abs(value)}</RankNumber>
            </Wrapper>
        );
    }

    // rank is going up
    // e.g: previous = 15; current = 10
    // 15 - 10 = 5 => going up by 5
    return (
        <Wrapper>
            <ArrowUp color='var(--salad-300)' /> <RankNumber>{value}</RankNumber>
        </Wrapper>
    );
}

const Wrapper = styled.span`
    display: flex;
    align-items: center;

    svg {
        margin-right: calc(var(--baseline) / 2);
    }
`;

const RankNumber = styled.span`
    min-width: calc(var(--baseline) * 2);
    text-align: right;
`;

type RankProps = {
    rank?: number | null;
};

const StyledRank = styled.span`
    text-align: right;
    display: block;
`;

export function Rank({ rank }: RankProps) {
    // still loading
    if (rank === undefined) {
        return <Loading />;
    }

    if (rank === null) {
        return <StyledRank>{'>'}20</StyledRank>;
    }

    return <StyledRank>{rank}</StyledRank>;
}

const StyledLoading = styled.div`
    display: flex;
    justify-content: center;
`;

const Loading = () => {
    return (
        <StyledLoading>
            <Spin size='xs' />
        </StyledLoading>
    );
};
