import React from 'react';
import { limitWidth } from '../styles';
import { tubicsLogo } from '../images';
import styled from 'styled-components';
import { titleState } from './page-title';
import { useRecoilState } from 'recoil';

export function Navbar() {
    const [pageTitle] = useRecoilState(titleState);

    return (
        <StyledNavbar>
            <NavbarInner>
                <LogoWrapper>
                    <img
                        src={`data:image/svg+xml;base64,${tubicsLogo}`}
                        alt={'Tubics Logo'}
                        height={60}
                        width={200}
                        color={'#fff'}
                    />
                </LogoWrapper>
                <div>
                    {pageTitle}
                </div>
            </NavbarInner>
        </StyledNavbar>
    );
}

const StyledNavbar = styled.nav`
    width: 100%;
    height: 75px;
    background-color: var(--tubics-color-dark, #25282d);
`;

const NavbarInner = styled.div`
    display: flex;
    align-items: center;
    height: 100%;

    ${limitWidth}
`;

const LogoWrapper = styled.div`
    align-self: flex-start;
`;