import React from 'react';
import styled from 'styled-components';
import { Stepper, Steps, useProgress } from '@biotic-ui/stepper';
import AddChannel from './channel';
import { AddKeywords } from './keyword';
import Loading from './loading';
import { Progress } from '../semcore';
import OnboardingErrorBoundary from '../component/error-handling/onboarding-error-boundary';

export const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 900px;
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const CurrentProgress = () => {
    const { step, steps } = useProgress();
    return (
        <Header>
            <StepLabels>
                <li>Connect YouTube Channel</li>
                <li>Import Keywords</li>
                <li>Start Tracking</li>
            </StepLabels>
            <Progress theme='#4FAE33' step={step + 1} steps={steps} />
        </Header>
    );
};

export default function Onboarding() {
    return (
        <OnboardingErrorBoundary>
            <Wrapper data-test='onboarding'>
                <Stepper>
                    <CurrentProgress />
                    <Steps>
                        <AddChannel />
                        <AddKeywords />
                        <Loading />
                    </Steps>
                </Stepper>
            </Wrapper>
        </OnboardingErrorBoundary>
    );
}

const StepLabels = styled.ol`
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: calc(var(--baseline) * 1.5);
`;

const Header = styled.header`
    width: 100%;
    margin-bottom: calc(var(--baseline) * 3);
    width: 616px;
`;
