import { useCallback } from 'react';
import useSWR, { mutate } from 'swr';
import { useHttp } from '../config';
import { setKeywordLimits, UserKey } from '../user';
import { AddKeywordsBody, ChannelSyncState, DateRange, DeleteKeywordsBody, IKeyword, ISerp, RankingsResultDto } from '../../types';

type Keywords = Array<IKeyword>;

const url = (channelId: string): string => `/api/channel/${channelId}/keywords`;

/*
 * useKeywords fetches the rankings for a given channel id
 */
export function useKeywords(
    channelId: string | null,
    dates: Array<string>,
    refresh = false,
    sort: string,
    order: string,
) {
    const http = useHttp();
    const options = {
        refreshInterval: refresh ? 3000 : 0,
    };

    const KEY = !channelId ? null : {
        url: `${url(channelId)}/query`,
        order,
        sort,
        dates,
    };

    const fetcher = async (
        url: string,
        order: string,
        sort: string,
        dates: Array<string>,
    ) => {
        const dateRanges = toRange(dates);
        return http.post(url, { dateRanges, order, sort }).then(({ data }) => data);
    };

    return useSWR<RankingsResultDto[]>(
        KEY,
        ({ url, order, sort, dates }) => fetcher(url, order, sort, dates),
        options,
    );
}

export function useTopKeywords(channelId: string | null, dates: Array<string>) {
    const http = useHttp();
    const dateRanges = toRange(dates);

    const KEY = !channelId ? null : {
        url: `${url(channelId)}/query`,
        type: 'top_keywords',
    };

    const fetcher = async (url: string) => {
        const data = {
            sort: 'rank',
            order: 'asc',
            page: 0,
            items: 5,
            dateRanges,
        };

        return http.post(url, data).then(({ data }) => data);
    };

    return useSWR<RankingsResultDto[]>(KEY, ({ url }) => fetcher(url));
}

export function useSyncStatus(channelId: string) {
    const base = url(channelId);
    return useSWR<ChannelSyncState>(`${base}/sync-status`, {
        refreshInterval: 2000,
        refreshWhenHidden: true,
    });
}

/*
 * useAddKeywords add a keyword to a given channel id
 */
export function useAddKeywords(
    channelId: string | null,
    dates: Array<string>,
    sort: string,
    order: string,
    tracking = true,
) {
    const axios = useHttp();
    const addKeywords = async ({ keywords }: AddKeywordsBody): Promise<RankingsResultDto[]> => {
        if (!channelId || channelId === '') {
            throw new Error(`useAddKeywords: Expected a channelId, instead got ${channelId}`);
        }

        if (!keywords || keywords.length === 0) {
            return [];
        }

        mutate(UserKey, setKeywordLimits(keywords.length), false);
        const URL = `${url(channelId)}/query`;
        const KEY = { url: URL, order, sort, dates };

        const { data: addedKeywords } = await axios.post(url(channelId), { keywords, tracking }).catch((error) => {
            if (error.response.status === 409) {
                return { data: [] };
            }

            throw error;
        });

        mutate(UserKey);
        mutate(KEY, addChannelKeywords(fromAdd(addedKeywords)));

        return addedKeywords;
    };

    return useCallback(addKeywords, [axios, channelId, dates, order, sort, tracking]);
}

/*
 * useDeleteKeywords delete a keyword from a given channel
 */
export function useDeleteKeywords(channelId: string | null, dates: Array<string>, sort: string, order: string) {
    const axios = useHttp();

    const deleteKeywords = async ({ keywordIds }: DeleteKeywordsBody): Promise<Keywords> => {
        if (!channelId || channelId === '') {
            throw new Error(`useDeleteKeywords: Expected a channelId, instead got ${channelId}`);
        }

        if (keywordIds.length === 0) {
            return [];
        }

        const URL = `${url(channelId)}/query`;
        const KEY = { url: URL, order, sort, dates };

        mutate(UserKey, setKeywordLimits(keywordIds.length * -1), false);
        mutate(KEY, removeKeywords(keywordIds), false);

        const payload = { data: { keywordIds } };
        const { data: deletedKeywords } = await axios.delete(url(channelId), payload);

        mutate(UserKey);
        mutate(KEY);

        return deletedKeywords;
    };

    return useCallback(deleteKeywords, [axios, channelId, dates, order, sort]);
}

export function useAveragePosition(channelId: string | null, dateRanges: Array<string>) {
    const http = useHttp();
    const KEY = channelId === null ? null : {
        url: '/api/channels/:channelId/stats',
        channelId,
        type: 'avg_position',
        dates: dateRanges,
    };

    const fetcher = async (url, channelId, dates) => {
        const parsedUrl = url.replace(':channelId', channelId);
        const dateRanges = toRange(dates);

        const response = await http.post(parsedUrl, {
            metrics: 'AVG_POSITION',
            dateRanges,
        });

        return {
            data: response.data?.current ?? 0,
        };
    };

    return useSWR<{ data: number }>(
        KEY,
        ({ url, channelId, dates }) => fetcher(url, channelId, dates),
    );
}

export type KeywordsDistribution = {
    top3: number;
    top10: number;
    top20: number;
    noRank: number;
};

export type KeywordDistributionResult = [KeywordsDistribution, KeywordsDistribution];

export function useKeywordDistribution(channelId: string | null, dateRanges: Array<string>) {
    const http = useHttp();
    const KEY = channelId === null ? null : {
        url: '/api/channels/:channelId/stats',
        channelId,
        type: 'keyword_distribution',
        dates: dateRanges,
    };

    const fetcher = async (url, channelId, dates) => {

        const parsedUrl = url.replace(':channelId', channelId);
        const dateRanges = toRange(dates);

        const response = await http.post(parsedUrl, {
            metrics: 'KEYWORD_DISTRIBUTION',
            dateRanges,
        });

        const data: KeywordDistributionResult = [
            response.data.comparison,
            response.data.target,
        ];

        return {
            data: data as KeywordDistributionResult,
        };
    };

    return useSWR<{ data: KeywordDistributionResult }>(
        KEY,
        ({ url, channelId, dates }) => fetcher(url, channelId, dates),
    );
}


export type RankChange = {
    up: number;
    down: number;
    stable: number;
};

export function useKeywordsRank(channelId: string | null, dateRanges: Array<string>) {
    const http = useHttp();
    const KEY = channelId === null ? null : {
        url: '/api/channels/:channelId/stats',
        channelId,
        type: 'keywords_rank',
        dates: dateRanges,
    };

    const fetcher = async (url, channelId, dates) => {
        const parsedUrl = url.replace(':channelId', channelId);
        const dateRanges = toRange(dates);

        const response = await http.post(parsedUrl, {
            metrics: 'RANK_CHANGE',
            dateRanges,
        });

        return {
            data: response.data,
        };
    };

    return useSWR<{ data: RankChange }>(
        KEY,
        ({ url, channelId, dates }) => fetcher(url, channelId, dates),
    );
}

export function useSerp(channelId: string | null, search: string) {
    const http = useHttp();

    return useSWR<ISerp[]>(
        (!channelId || !search) ? null : `${url(channelId)}/yt_serp?keyword=${search}`,
        async (url: string) => {
            return http
                .get(url)
                .then((res) => res.data);
        },
    );
}

const addChannelKeywords =
    (keywords: RankingsResultDto[]) =>
        (currentKeywords: RankingsResultDto[] = []): RankingsResultDto[] => {
            return [...keywords, ...currentKeywords];
        };

const removeKeywords =
    (keywordIds: Array<number>) =>
        (currentKeywords: Keywords): Keywords => {
            return currentKeywords?.filter((keyword) => !keywordIds.includes(keyword.id)) ?? [];
        };

function toRange(dates: Array<string>): Array<DateRange> {
    const ranges: Array<DateRange> = [];

    for (let i = 0; i < dates.length; i = i + 2) {
        const start = dates[i];
        const end = dates[i + 1];

        if (!start || !end) {
            break;
        }

        ranges.push({ start: new Date(start), end: new Date(end) });
    }

    return ranges;
}

function fromAdd(keywords: IKeyword[]): RankingsResultDto[] {
    return keywords.map((keyword) => {
        return {
            ...keyword,
            rank: undefined,
            currentResult: undefined,
            previousResult: undefined,
        };
    });
}
