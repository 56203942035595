import App from './app';
import { BrowserRouter } from 'react-router-dom';

import GlobalError from './asset/global_error.png';
import { AppRoot, Config, getConfig } from './core';

const config = getConfig(process.env, {
    error: {
        global: GlobalError,
    },
});

export default function Bootstrap() {
    return (
        <BrowserRouter>
            <Config.Provider value={config}>
                <AppRoot>
                    <App />
                </AppRoot>
            </Config.Provider>
        </BrowserRouter>
    );
}
