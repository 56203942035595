import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useKeywordLimit } from '../core';
import { Info, Tooltip } from '../semcore';

const StyledLimit = styled.span<{ error: boolean }>`
    color: ${(p) => (p.error ? '#fff' : 'var(--color-copy)')};
    text-align: right;
    border: 1px solid ${(p) => (p.error ? 'var(--orange)' : 'var(--gray80)')};
    border-radius: 14px;
    padding: 1px 7px;
    background: ${(p) => (p.error ? 'var(--orange)' : 'inherit')};
    margin-left: calc(var(--baseline) / 2);
`;

interface Props {
    addedKeywords: number;
}

const StyledInfo = styled.span`
    svg {
        fill: var(--stone);
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Popper = styled(Tooltip.Popper)`
    max-width: 280px;
    width: 280px;
`;

const Text = styled.p`
    margin-bottom: 0;
`;

export function Limit({ addedKeywords }: Props) {
    const { t } = useTranslation();
    const keywords = useKeywordLimit();
    const total = keywords.total + addedKeywords;
    const error = total > keywords.limit;

    return (
        <Wrapper>
            <Tooltip placement='left'>
                <Tooltip.Trigger>
                    <StyledInfo>
                        <Info />
                    </StyledInfo>
                </Tooltip.Trigger>
                <Popper>
                    <h5>{t('limit.title')}</h5>
                    <Text>{t('limit.info')}</Text>
                </Popper>
            </Tooltip>
            {!error && (
                <StyledLimit error={false}>
                    {total} / {keywords.limit}
                </StyledLimit>
            )}

            {error && (
                <Tooltip placement='left' theme='warning'>
                    <Tooltip.Trigger>
                        <StyledLimit error={true}>
                            {total} / {keywords.limit}
                        </StyledLimit>
                    </Tooltip.Trigger>
                    <Popper>
                        <p>{t('limit.error_reason')}</p>
                        <Text>{t('limit.error_info')}</Text>
                    </Popper>
                </Tooltip>
            )}
        </Wrapper>
    );
}
