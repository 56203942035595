import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelectedChannel } from '../util/channel';
import { getShortNumberFormat } from '../util/numbers';
import EmptyTableIcon from '../component-assets/empty-table-icon';
import WarningIcon from '../component-assets/warning-icon';
import { Loading, useAddAction, useSerp } from '../core';
import { Modal } from './modal';
import { LinkExternal, Pagination, Table, Text } from '../semcore';
import { ISerp, ISerpChannel } from '../types';

type SerpTableProps = {
    search: string;
    open: boolean;
    close: () => void;
}

export default function SerpTable({ search, open, close }: SerpTableProps) {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const channelId = useSelectedChannel();
    const { data, error } = useSerp(channelId, search);
    const addAction = useAddAction();
    const pageSize = 5;

    const tableData = !data ? [] : data.filter(item => isVisibleOnPage(item.position_on_page));

    useEffect(() => {
        setTotalPages(Math.ceil((data?.length || 1) / pageSize));
    }, [data]);

    function changePage(desiredPage: number): void {
        setPage(Math.min(desiredPage, totalPages));
    }

    function isVisibleOnPage(index: number): boolean {
        return Math.ceil(index / pageSize) === page;
    }

    function onVideoLinkClick() {
        addAction({ action: 'Clicked video link in serp' });
    }

    function onChannelLinkClick() {
        addAction({ action: 'Clicked channel link in serp' });
    }

    return (
        <Modal open={open} onClose={close}>
            <ModalContent>
                <Text tag='h2' size={500} mb={2}>{t('serp.title', { search })}</Text>
                <Text tag='p' size={200} mb={6} fontWeight={'lighter'} color='var(--gray60)'>
                    {t('serp.title_note')}
                </Text>
                {(!data && !error) && <Loading />}
                {
                    (data && data.length < 1 && !error) &&
                    <WarningContainer>
                        <EmptyTableIcon />
                        <WarningText>
                            <Text tag='p' size={300} mb={1} color='var(--gray60)'>{t('serp.no_data')}</Text>
                            <Text
                                tag='p'
                                size={200}
                                color='var(--gray60)'
                                fontWeight={'lighter'}
                            >{t('serp.no_data_note')}</Text>
                        </WarningText>
                    </WarningContainer>
                }
                {
                    data && data.length > 0 &&
                    <>
                        <TableWrapper>
                            <Table data={tableData}>
                                <Table.Head>
                                    <Table.Column name='position_on_page' wMax='50px'>
                                        {t('serp.columns.rank')}
                                    </Table.Column>
                                    <Table.Column name='video'>
                                        {t('serp.columns.video')}
                                    </Table.Column>
                                    <Table.Column name='channel'  wMax='250px'>
                                        {t('serp.columns.channel')}
                                    </Table.Column>
                                    <Table.Column
                                        name='views'
                                        style={{ textAlign: 'right' }}
                                        wMax='100px'
                                    >
                                        {t('serp.columns.views')}
                                    </Table.Column>
                                </Table.Head>
                                <Table.Body>
                                    <Table.Cell data={tableData} name='position_on_page'>
                                        {(_, row) => ({
                                            children: (
                                                <Rank owned={belongsToMyChannel(row, channelId)}>
                                                    {row.position_on_page}
                                                </Rank>
                                            )
                                        })}
                                    </Table.Cell>
                                    <Table.Cell data={tableData} name='video'>
                                        {(_, row) => ({
                                            children: (
                                                <Video
                                                    video={row}
                                                    onVideoClick={onVideoLinkClick}
                                                />
                                            )
                                        })}
                                    </Table.Cell>
                                    <Table.Cell data={tableData} name='channel'>
                                        {(_, row) => ({
                                            children: (
                                                <Channel
                                                    channel={row.channel}
                                                    owned={belongsToMyChannel(row, channelId)}
                                                    onChannelClick={onChannelLinkClick}
                                                />
                                            )
                                        })}
                                    </Table.Cell>
                                    <Table.Cell data={tableData} name='views'>
                                        {(_, row) => ({
                                            children: (
                                                <ViewsCount>
                                                    {row.views ? getShortNumberFormat(row.views) : 'n/a'}
                                                </ViewsCount>
                                            )
                                        })}
                                    </Table.Cell>
                                </Table.Body>
                            </Table>
                        </TableWrapper>
                        {
                            totalPages > 1 &&
                            <PaginationWrapper>
                                <Pagination currentPage={page} onCurrentPageChange={changePage} totalPages={totalPages} />
                            </PaginationWrapper>
                        }
                    </>
                }
                {
                    error &&
                    <WarningContainer>
                        <WarningIcon />
                        <WarningText>
                            <Text tag='p' size={300} mb={1} color='var(--gray60)'>{t('serp.error')}</Text>
                            <Text tag='p' size={200} color='var(--gray60)' fontWeight={'lighter'}>
                                {t('serp.error_note')}
                            </Text>
                        </WarningText>
                    </WarningContainer>
                }
            </ModalContent>
        </Modal>
    );
}

const Rank = ({ owned, children }: { owned: boolean, children: any }) => {
    return (
        <RankContainer className={owned ? 'mine' : ''}>
            {children}
        </RankContainer>
    );
};

const Video = ({ video, onVideoClick }: { video: ISerp, onVideoClick: () => any }) => {
    const { t } = useTranslation();
    let thumbnailUrl;

    if (video.thumbnail) {
        const firstThumbnail = Object.keys(video.thumbnail)[0] as 'static' | 'rich';
        thumbnailUrl = video.thumbnail[firstThumbnail];
    }

    return (
        <VideoData>
            <VideoThumbnail>
                <a onClick={onVideoClick} tabIndex={0} href={video.link} target='_blank' rel='noopener noreferrer'>
                    <img src={thumbnailUrl} width='120' height='68' alt='' />
                    <VideoLength>{video.length}</VideoLength>
                </a>
            </VideoThumbnail>
            <VideoDetails>
                <VideoLinkSection title={video.title}>
                    <a
                        onClick={onVideoClick}
                        tabIndex={0}
                        href={video.link}
                        target='_blank'
                        rel='noopener noreferrer'
                    >{video.title} <LinkExternal /></a>
                    <PublishDate>
                        {!(video.published_date || '').startsWith('Streamed') ? t('serp.published') : ''}
                        &nbsp;{video.published_date}</PublishDate>
                </VideoLinkSection>
            </VideoDetails>
        </VideoData>
    );
};

const Channel = ({ channel, owned, onChannelClick }: { channel: ISerpChannel, owned: boolean, onChannelClick: () => any }) => {
    const { t } = useTranslation();

    return (
        <>
            <ChannelLink
                onClick={onChannelClick}
                tabIndex={1}
                href={channel.link}
                target='_blank'
                rel='noopener noreferrer'
            >{channel.name} <LinkExternal /></ChannelLink>
            {owned && <Tag>{t('serp.you')}</Tag>}
        </>
    );
};

const belongsToMyChannel = (video: ISerp, channelId: string | null): boolean => {
    return channelId ? (video.channel?.link || '').endsWith(channelId) : false;
};

const ModalContent = styled.section`
    min-width: 890px;
    min-height: 640px;
`;

const WarningContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
    margin-bottom: 150px;
`;

const WarningText = styled.section`
    margin-top: 20px;
    text-align: center;
`;

const TableWrapper = styled.section`
    width: 900px;
`;

const RankContainer = styled.div`
    width: 20px;
    height: 20px;
    text-align: center;

    &.mine {
        border-radius: 50%;
        background-color: #0071BC;
        color: white;
        line-height: 20px;
        font-size: 12px;
    }
`;

const VideoData = styled.section`
    display: flex;
    overflow: hidden;
    gap: 20px;
`;

const VideoThumbnail = styled.div`
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    user-select: none;
    min-width: 120px;
    max-width: 120px;
    height: 68px;
`;

const VideoLength = styled.div`
    background-color: #000;
    color: white;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 4px;
    line-height: 9px;
    padding: 3px 4px;
    border-radius: 2px;
    letter-spacing: 0px;
    flex-direction: row;
    align-items: center;
    display: inline-flex;

    &:hover, &:active, &:visited {
        color: white;
    }
`;

const VideoDetails = styled.section`
    display: flex;
    color: var(--black);
    width: 100%;
`;

const VideoLinkSection = styled.section`
    display: flex;
    flex-direction: column;

    a {
        display: inline-flex;
        gap: 3px;
        align-items: center;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        svg {
            fill: #A6B0B3;
        }

        :hover {
            text-decoration: underline;
        }
    }
`;

const PublishDate = styled.span`
    color: var(--gray60);
`;

const ChannelLink = styled.a`
    display: inline-flex;
    gap: 3px;
    align-items: center;
    color: var(--gray60);

    &:active, &:visited {
        color: var(--gray60);
    }

    &:hover {
        color: var(--gray60);
        text-decoration: underline;
    }

    svg {
        fill: #A6B0B3;
    }
`;
const Tag = styled.div`
    display: inline-flex;
    color: #0071BC;
    background-color: #E0EAF4;
    border-radius: 15px;
    margin-left: 8px;
    padding: 0px 7px;
    font-size: 10px;
    line-height: 16px;
    align-items: center;
`;

const ViewsCount = styled.span`
    white-space: nowrap;
`;

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    user-select: none;
`;
