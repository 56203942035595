import WarningXS from '@semcore/ui/icon/Warning/m';
import InfoXS from '@semcore/ui/icon/Info/m';
import ArrowDownXS from '@semcore/ui/icon/ArrowDown/m';
import ArrowUpXS from '@semcore/ui/icon/ArrowUp/m';
import ArrowRightXS from '@semcore/ui/icon/ArrowRight/m';
import ChevronDownS from '@semcore/ui/icon/ChevronDown/m';
import TrashS from '@semcore/ui/icon/Trash/m';
import MathPlusXS from '@semcore/ui/icon/MathPlus/m';
import GlobeXS from '@semcore/ui/icon/Globe/m';
import MathMinusXS from '@semcore/ui/icon/MathMinus/m';
import QuestionAlt from '@semcore/ui/icon/Question/m';
import Youtube from '@semcore/ui/icon/Youtube/m';
import FileDownloadXS from '@semcore/ui/icon/FileDownload/m';
import SerpXS from '@semcore/ui/icon/Serp/m';
import VideoAltXS from '@semcore/ui/icon/VideoAlt/m';
import LinkExternalXS from '@semcore/ui/icon/LinkExternal/m';
import CartXS from '@semcore/ui/icon/Cart/m';

export const Warning = WarningXS;
export const Info = InfoXS;
export const ArrowDown = ArrowDownXS;
export const ArrowUp = ArrowUpXS;
export const ArrowRight = ArrowRightXS;
export const ChevronDown = ChevronDownS;
export const Trash = TrashS;
export const MathPlus = MathPlusXS;
export const Global = GlobeXS;
export const MathMinus = MathMinusXS;
export const QuestionMark = QuestionAlt;
export const YoutubeLogo = Youtube;
export const FileDownload = FileDownloadXS;
export const Serp = SerpXS;
export const VideoAlt = VideoAltXS;
export const LinkExternal = LinkExternalXS;
export const Cart = CartXS;
