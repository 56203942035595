import { useEffect, useMemo } from 'react';
import { useValidateToken } from '../user';
import { RecoilRoot } from 'recoil';
import { getSemrushSDK, Http, Methods, useConfig, usePageViews } from '../config';
import Axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { SWRConfig } from 'swr';
import { SegmentContext, useInitSegment } from '../segment';
import { useAddAction } from '../actions';
import { Loading } from './loading';
import styled from 'styled-components';
import { PerformanceTrace, getPerformance, trace } from 'firebase/performance';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

interface Props {
    children?: JSX.Element[] | JSX.Element;
}

export function App({ children }: Props) {
    usePageViews();

    const config = useConfig();
    const segment = useInitSegment();
    const [validated, error] = useValidateToken();
    const action = useAddAction();

    useEffect(() => {
        if (error) {
            action({ action: 'Invalid Token Error' });
        }
    }, [error, action]);

    if (error) {
        return (
            <ErrorWrapper>
                <img src={config.error.global} alt='' />
                <h2>Something went wrong</h2>
                <p>There is a problem in loading your dashboard, try refreshing the page, or contact our support.</p>
            </ErrorWrapper>
        );
    }

    return (
        <RecoilRoot>
            <SegmentContext.Provider value={segment}>
                {validated ? children : <Loading />}
            </SegmentContext.Provider>
        </RecoilRoot>
    );
}

export function AppRoot({ children }: Props) {
    const { backendBaseUrl } = useConfig();
    const semrush = getSemrushSDK();

    const firebaseConfig = useConfig().firebase;
    const app = initializeApp(firebaseConfig);
    const firebasePerformance = getPerformance(app);
    getAnalytics(app);

    const axios = useMemo<AxiosInstance>(() => {
        const axios = Axios.create({ baseURL: backendBaseUrl });

        axios.interceptors.request.use(async (config: InternalAxiosRequestConfig<any>) => {
            const token = await semrush.client<[], string>(Methods.GetAccessToken);

            let perfTrace: PerformanceTrace | undefined;
            try {
                //Todo implement url to template mapping
                perfTrace = trace(firebasePerformance, config.url || 'unknown_url');
                perfTrace.start();
            } catch (error) {
                console.error('Error in request interceptor, , failed to monitor performance:', error);
            }

            const newConfig = { ...config, metadata: { perfTrace } };

            newConfig.headers.set('Authorization', `Bearer ${token}`);
            newConfig.headers.set('Access-Control-Allow-Origin', '*');

            if (newConfig.headers) {
                newConfig.headers.set('Authorization', `Bearer ${token}`);
                newConfig.headers.set('Access-Control-Allow-Origin', '*');
            }

            return newConfig;

        });

        axios.interceptors.response.use(
            (response) => {
                try {
                    const config = response.config as any;
                    const perfTrace = config?.metadata?.perfTrace;
                    if (perfTrace) {
                        perfTrace.stop();
                    }
                } catch (error) {
                    console.error('Error in response interceptor, failed to monitor performance:', error);
                }

                return response;
            },
            (error) => {
                try {
                    const perfTrace = error.config?.metadata?.perfTrace;
                    if (perfTrace) {
                        perfTrace.stop();
                    }
                } catch (e) {
                    console.error('Error in response error interceptor, failed to monitor performance:', e);
                }
                return Promise.reject(error);
            }
        );
      

        return axios;
    }, [backendBaseUrl, semrush, firebasePerformance]);

    const fetcher = async (url: string) => {
        return axios.get(url).then(({ data }) => {
            return data;
        });
    };

    return (
        <Http.Provider value={axios}>
            <SWRConfig value={{ fetcher }}>
                <App>{children}</App>
            </SWRConfig>
        </Http.Provider>
    );
}

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: calc(var(--baseline) * 6);

    img {
        margin-bottom: calc(var(--baseline) * 3);
    }

    p {
        max-width: 360px;
    }
`;
