import { FormEvent, useEffect, useState } from 'react';
import { useData, useNext } from '@biotic-ui/stepper';
import { Button, Input, Notice, Tooltip, Warning } from '../semcore';
import { Text, Title, Wrapper } from './shared';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAddAction, useAddChannel, useValidateChannelURL } from '../core';
import { ActionPosition, AddChannelBody, ErrorCode, IChannelDto } from '../types';

type NextFn = () => void | null;

const Form = styled.form`
    display: flex;
    margin-bottom: calc(var(--baseline) * 2);

    input {
        flex-grow: 1;
        margin-right: 1rem;
    }

    button {
        margin-left: calc(var(--baseline) * 2);
        flex-shrink: 0;
    }
`;

const Trigger = styled(Tooltip.Trigger)`
    flex-grow: 1;
`;

const ErrorTitle = styled.h5`
    margin-bottom: calc(var(--baseline) / 2);
    line-height: 1;
`;

type Err = null | { message: string };

type Data = IChannelDto & { url: string };

interface AddChannelProps {
    error: Err;
    onError: (err: Err) => void;
    loading: boolean;
    onSetLoading: (loading: boolean) => void;
    onNext: NextFn;
    onData: (channel: Data | null) => void;
    initialData: string;
    onAddChannel: (channel: AddChannelBody) => Promise<IChannelDto | null>;
    onAddAction: (data: any) => void;
}

export function AddChannel({
    error,
    onError,
    loading,
    onSetLoading,
    onNext,
    onData,
    initialData,
    onAddChannel,
    onAddAction,
}: AddChannelProps) {
    const { t } = useTranslation();
    const [channelUrl, setChannelUrl] = useState(initialData);
    const isValid = useValidateChannelURL(channelUrl);

    useEffect(() => {
        onAddAction({ action: 'Viewed Channel', data: { position: ActionPosition.ONBOADING } });
    }, [onAddAction]);

    async function handleAdd(e: FormEvent) {
        e.preventDefault();

        onAddAction({ action: 'Clicked Add Channel Button', data: { position: ActionPosition.ONBOADING } });

        try {
            onSetLoading(true);
            const addedChannel = await onAddChannel({ channel: channelUrl });
            if (addedChannel !== null) {
                onData({ ...addedChannel, url: channelUrl });
                onNext && onNext();
            }
        } catch (error) {
            if (error?.response?.status === 400 && error.response?.data.code === ErrorCode.CHANNEL_INVALID_URL) {
                onError(error.response.data);
                onAddAction({ action: 'Invalid Channel', data: { position: ActionPosition.ONBOADING, channelUrl } });
            } else if (error?.response?.status === 404) {
                onError({ message: t('error.channel_not_found', { url: channelUrl }) });
                onAddAction({ action: 'Channel Not Found', data: { position: ActionPosition.ONBOADING, channelUrl } });
            } else {
                onError(error);
                onAddAction({ action: 'Channel Error', data: { position: ActionPosition.ONBOADING, channelUrl } });
            }

            onSetLoading(false);
        }
    }

    function handleChange(value: string) {
        if (error !== null) {
            onError(null);
        }

        setChannelUrl(value.trim());
    }

    const isBlocked = channelUrl.length === 0 || loading;

    return (
        <Wrapper>
            <Title>{t('onboarding.add_channel.title')}</Title>

            <Text>{t('onboarding.add_channel.description')}</Text>

            <Form onSubmit={handleAdd}>
                <Tooltip visible={!isValid} theme='warning'>
                    <Trigger>
                        <Input size='l' type='text' value={channelUrl} onChange={handleChange} />
                    </Trigger>
                    <Tooltip.Popper 
                        style={{
                            whiteSpace: 'break-spaces',
                            maxWidth: '350px'
                        }}
                    >
                        {t('onboarding.add_channel.invalid').toString()}
                    </Tooltip.Popper>
                </Tooltip>
                <Button size='l' use='primary' theme='success' onClick={handleAdd} disabled={isBlocked}>
                    {loading ? <span>{t('app.loading')}</span> : <span>{t('app.add_channel')}</span>}
                </Button>
            </Form>

            <Notice theme='warning' hidden={error === null}>
                <Notice.Label>
                    <Warning />
                </Notice.Label>
                <Notice.Content>
                    <ErrorTitle>{t('onboarding.add_channel.error_title')}</ErrorTitle>
                    <span>{t('onboarding.add_channel.error_info')}</span>
                </Notice.Content>
            </Notice>
        </Wrapper>
    );
}

export default function AddChannelContainer() {
    const [error, setError] = useState<Err>(null);
    const [loading, setLoading] = useState(false);
    const onNext = useNext() as NextFn;
    const [{ url }, setData] = useData({ url: '' });
    const addChannel = useAddChannel();
    const addAction = useAddAction();

    return (
        <AddChannel
            error={error}
            onError={err => setError(err)}
            loading={loading}
            onSetLoading={setLoading}
            onNext={onNext}
            onData={setData}
            initialData={url}
            onAddChannel={addChannel}
            onAddAction={addAction}
        />
    );
}
