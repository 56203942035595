import React from 'react';
import SemrProgress from '@semcore/ui/progress-bar';

interface Props {
    steps: number;
    step: number;
    theme: string;
}

export function Progress({ steps, step, theme }: Props) {
    return (
        <SemrProgress size="m" duration={0} theme="invert" value={(step / steps) * 100}>
            <SemrProgress.Value theme={theme} />
        </SemrProgress>
    );
}
