import { useMemo } from 'react';
import { Chart, ErrorChart, getColor } from '../../component/chart';
import { RankChange } from '../../component/rank';
import styled from 'styled-components';
import { useSelectedChannel } from '../../util/channel';
import { useFormatDates, useSubDays, useTomorrow, useWeekBefore } from '../../util/date';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';
import { Plot, Donut, Skeleton, Text, Flex } from '../../semcore';
import { KeywordDistributionResult, useKeywordDistribution } from '../../core';

type DistributionRow = {
    key: string;
    currentValue: number;
    lastValue: number;
    color: string;
};

type KeywordDistributionProps = {
    data?: KeywordDistributionResult;
    error?: any;
};

export function KeywordDistribution({ data, error }: KeywordDistributionProps) {
    const { t } = useTranslation();

    const distribution = useMemo(() => {
        if (data === undefined) {
            return {};
        }

        return data?.[1] ?? {};
    }, [data]);

    const table = useMemo(() => {
        if (data === undefined) {
            return [];
        }

        return getDistributionTable(data);
    }, [data]);

    const totalKeywords = useMemo(() => {
        if (data === undefined) {
            return {};
        }

        const distributedData = data?.[1] ?? {};
        if (distributedData) {
            return Object.keys(distributedData).reduce((acc, key) => acc + distributedData[key], 0)
        }
        return 0;
    }, [data]);

    if (error !== undefined) {
        return <ErrorChart title={t('keyword_distribution.title')} info={t('keyword_distribution.info')} />;
    }

    return (
        <Chart title={t('keyword_distribution.title')} info={t('keyword_distribution.info')}>
            <DistributionWrapper>
                {data === undefined ? (
                    <Plot width={100} height={100} data={distribution}>
                        <Donut 
                            innerRadius={20}
                            outerRadius={40}
                        >
                            <Donut.EmptyData />
                            <Donut.Pie dataKey='a' name='a' />
                            <Donut.Pie dataKey='b' name='b'  />
                            <Donut.Pie dataKey='c' name='c'/>
                        </Donut>
                    </Plot>
                ) : (
                    <Plot
                        height={100}
                        width={100} 
                        data={distribution}
                        style={{ margin: '0 calc(var(--baseline) * 3) 0 0' }}
                    >
                        <Donut
                            innerRadius={20}
                            outerRadius={40}
                        >
                            {Object.keys(distribution).map((key) => (
                                <Donut.Pie
                                    key={key} 
                                    dataKey={key}
                                    name={key}
                                    color={getColor(key)}
                                />
                            ))}
                        </Donut>
                        <Donut.Tooltip>
                            {({ dataKey, name, data }) => {
                                const value = +data[dataKey];

                                const valuesDisplayStr = pluralize('result', value || 0, true);
                                const percent = (value / totalKeywords * 100)?.toFixed();

                                let content: string;

                                switch (dataKey) {
                                    case 'top3':
                                        content = `${valuesDisplayStr} between position 1-3 (${percent}%)`;
                                        break;
                                    case 'top10':
                                        content = `${valuesDisplayStr} between position 4-10 (${percent}%)`;
                                        break;
                                    case 'top20':
                                        content = `${valuesDisplayStr} between position 11-20 (${percent}%)`;
                                        break;
                                    case 'noRank':
                                        content = `${valuesDisplayStr} with position >20 (${percent}%)`;
                                        break;
                                    default:
                                        content = name;
                                }

                            return {
                                children: (
                                <>
                                    <Donut.Tooltip.Title>{name}</Donut.Tooltip.Title>
                                    <Flex justifyContent='space-between'>
                                        <Text bold>{content}</Text>
                                    </Flex>
                                </>
                                ),
                            };
                            }}
                        </Donut.Tooltip>
                    </Plot>
                )}
                {data === undefined ? (
                    <Skeleton h='88'>
                        <Skeleton.Text amount={4} />
                    </Skeleton>
                ) : (
                    <Text
                        tag='table'
                        size={100}
                        style={{
                            marginTop: '-4px',
                            borderCollapse: 'separate',
                            borderSpacing: '0 4px',
                        }}
                    >
                        <tbody>
                        {table.map((row) => {
                            const change = row.currentValue - row.lastValue;
                            return (
                                <tr key={row.key}>
                                    <td>
                                        <Box color={row.color} />
                                    </td>
                                    <Text color='gray20' pr={4} tag='td'>
                                        {t(`keyword_distribution.${row.key}`)}
                                    </Text>
                                    <Text pr={4} tag='td' color='gray60'>
                                        <Ranks>{row.currentValue}</Ranks>
                                    </Text>
                                    <Text color='gray60' tag='td'>
                                        <RankChange value={change} />
                                    </Text>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Text>
                )}
            </DistributionWrapper>
        </Chart>
    );
}

export default function KeywordDistributionContainer() {
    const channelId = useSelectedChannel();
    const tomorrow = useTomorrow();
    const comparison = useSubDays(tomorrow, 7);

    const lastWeek = useWeekBefore(comparison);
    const currentWeek = useWeekBefore(tomorrow);
    const ranges = useFormatDates([lastWeek, comparison, currentWeek, tomorrow]);

    const { data, error } = useKeywordDistribution(channelId, ranges);
    return <KeywordDistribution data={data?.data} error={error} />;
}

type DistributionResult = { [key: string]: number };
type Distribution = [DistributionResult, DistributionResult];

function getDistributionTable([lastWeek, currentWeek]: Distribution): Array<DistributionRow> {
    const order = ['top3', 'top10', 'top20', 'noRank'];
    return order.map((key) => {
        const currentValue = currentWeek[key] ?? 0;
        const lastValue = lastWeek[key] ?? 0;
        return { key, currentValue, lastValue, color: getColor(key) };
    });
}

const Box = styled.div<{ color: string }>`
    --size: calc(var(--baseline) * 1.5);
    width: var(--size);
    height: var(--size);
    background: ${(p) => p.color};
    border-radius: calc(var(--baseline) / 2);
    margin-right: calc(var(--baseline) / 2);
`;

const Ranks = styled.span`
    text-align: right;
    display: block;
`;

const DistributionWrapper = styled.div`
    display: flex;
`;
