import { useState, useMemo } from 'react';
import { subWeeks, subDays, format, addDays } from 'date-fns';

export function useToday() {
    return useState<Date>(() => {
        return new Date();
    });
}

export function useTomorrow() {
    const [today] = useToday();
    return addDays(today, 1);
}

export function useSubWeeks(date: Date, weeks = 0) {
    return useMemo<Date>(() => {
        return subWeeks(date, weeks);
    }, [date, weeks]);
}

export function useSubDays(date: Date, days = 0) {
    return useMemo(() => {
        return subDays(date, days);
    }, [date, days]);
}

export function useFormatDates(dates: Array<Date>): Array<string> {
    return useMemo(() => {
        return dates.map((date) => format(date, 'yyyy-MM-dd'));
    }, [dates]);
}

export function useWeekBefore(today: Date) {
    return useSubWeeks(today, 1);
}

export function exportDate() {
    return format(new Date(), 'yyyy-MM-dd');
}
