import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export function usePageViews() {
    const location = useLocation();

    useEffect(() => {
        if (!location) {
            return;
        }
    }, [location]);
}
