import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import widgetIcon from '../asset/widget-empty-venn-chart.svg';
import { Button } from '../component/button';
import ConnectChannelHeader from './component/connect-channel-header';

export function EmptyChannel() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function connectChannel() {
        navigate('/onboarding');
    }

    return (
        <Container>
            <ConnectChannelHeader />
            <img src={widgetIcon} alt="empty channel icon" />
            <Head>{t('empty_channel.title')}</Head>
            <Text>
                <Paragraph>{t('empty_channel.description_line1')}</Paragraph>
                <Paragraph>{t('empty_channel.description_line2')}</Paragraph>
            </Text>
            <Button use="primary" onClick={connectChannel}>
                {t('empty_channel.button')}
            </Button>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Head = styled.h5`
    font-size: 16px;
    margin: calc(var(--baseline) * 2) 0;
`;

const Text = styled.div`
    margin-bottom: calc(var(--baseline) * 2);
`;
const Paragraph = styled.p`
    font-size: 14px;
    margin: 0;
    color: #757575;
`;
