import React from 'react';
import SemrPagination from '@semcore/ui/pagination';

interface Props {
    currentPage: number;
    onCurrentPageChange: (pageNumber: number) => void;
    totalPages: number;
}

export function Pagination({ currentPage, onCurrentPageChange, totalPages }: Props) {
    return (
        <SemrPagination currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} totalPages={totalPages} />
    );
}
