import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useHttp } from '../config';
import useSWR from 'swr';
import { IChannelDto, IUserDto, KeywordLimit } from '../../types';

export type UserState = IUserDto | null;
export const UserKey = '/api/users/me';

/*
 * useValidateToken grabs a JWT from useConfig and validates the user
 */
export function useValidateToken(): [boolean, boolean] {
    const [validated, setValidated] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const axiosInstance = useHttp();
    const api = useRef(axiosInstance);

    useEffect(() => {
        const cancelRequest = axios.CancelToken.source();
        const url = '/api/users/auth';

        api.current
            .post(url, null, { cancelToken: cancelRequest.token })
            .then(async (res) => {
                if (res.status === 200) {
                    setValidated(true);
                    setError(false);
                } else {
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            });

        return () => {
            cancelRequest.cancel();
        };
    }, [api, setError, setValidated]);

    return [validated, error];
}

/*
 * useUser gets the user object
 */
export function useUser() {
    return useSWR<IUserDto>(UserKey);
}

export const DefaultLimit = {
    total: 0,
    limit: 0,
};

export function useKeywordLimit(): KeywordLimit {
    const { data: user } = useUser();

    if (!user) {
        return DefaultLimit;
    }

    return user.keywords;
}

export enum KeywordError {
    Limit,
    CharLimit,
}

type CharLimitError = { type: KeywordError.CharLimit; error: Array<{ index: number; keyword: string }> };
type LimitError = { type: KeywordError.Limit; message: string };
type Err = CharLimitError | LimitError;

type KeywordHook = [
    {
        value: Array<string>;
        text: string;
        errors: Array<Err>;
        limit: number;
    },
    (s: string) => void
];

interface KeywordLimitHook {
    saving: boolean;
}

export function useKeywordLimits({ saving = false }: KeywordLimitHook): KeywordHook {
    const { limit, total } = useKeywordLimit();

    const [keywords, setKeywords] = useState<Array<string>>([]);
    const [text, setText] = useState('');
    const [errors, setErrors] = useState<Array<Err>>([]);

    const handleKeywordsChange = useCallback(
        (str: string) => {
            setText(str);

            const keywords = str.split(/\n/).filter((line) => line.length > 0);

            setKeywords(keywords);
            const totalKeywords = total + keywords.length;
            const limitExceeded = totalKeywords > limit;

            const charLimit: Array<{ keyword: string; index: number }> = keywords
                .map((keyword, index) => ({ keyword, index }))
                .filter((value) => value.keyword.length > 255);

            const errors: Array<Err> = [];
            if (charLimit.length > 0) {
                errors.push({
                    type: KeywordError.CharLimit,
                    error: charLimit,
                });
            }

            if (limitExceeded) {
                errors.push({
                    type: KeywordError.Limit,
                    message: `Keyword limit of ${limit} reached`,
                });
            }

            setErrors(errors);
        },
        [limit, total],
    );

    const value = saving ? [] : keywords;
    return [{ value, text, errors, limit }, handleKeywordsChange];
}

/*
 * getToken returns the jwt from the query parameters
 */
export function getToken(w: Window): string | null {
    const { search } = w.location;
    const params = new URLSearchParams(search);
    return params.get('jwt');
}

export const removeChannel = (channelId: string | null | undefined) => (user: IUserDto): IUserDto => {
    return {
        ...user,
        channels: user.channels?.filter((channel) => channel.id !== channelId) ?? [],
    };
};

export const addChannel = (channel: IChannelDto) => (user: IUserDto): IUserDto => {
    const inChannels = user.channels.some((c) => c.id === channel.id);

    if (inChannels) {
        return user;
    }

    return {
        ...user,
        channels: [...user.channels, channel],
    };
};

export const setKeywordLimits = (addedKeywords: number) => (user: IUserDto): IUserDto => {
    return {
        ...user,
        keywords: {
            ...user.keywords,
            total: user.keywords.total + addedKeywords,
        },
    };
};

// add comment
