import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './button';
import styled from 'styled-components';
import EmptyTable from '../asset/empty_table.svg';

type Props = {
    onAdd: () => void;
};

export function EmptyKeywords({ onAdd }: Props) {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <img src={EmptyTable} alt={t('keywords.empty.title')} />

            <div>
                <h4>{t('keywords.empty.title')}</h4>
                <Description>
                    {t('keywords.empty.description1')}
                    <a href='https://youtu.be/beaiKZuxdFc' rel='noopener noreferrer' target='_blank'>
                        {' '}
                        {t('keywords.empty.link1')}{' '}
                    </a>
                    or
                    <a href='https://youtu.be/p0L-GKEwfbQ' rel='noopener noreferrer' target='_blank'>
                        {' '}
                        {t('keywords.empty.link2')}
                    </a>
                    .
                </Description>
                <Button use='primary' onClick={() => onAdd()}>
                    {t('app.add_keywords')}
                </Button>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin-bottom: calc(var(--baseline) * 5);
    display: flex;

    img {
        margin-right: calc(var(--baseline) * 5);
    }
`;

const Description = styled.p`
    max-width: 500px;
`;
