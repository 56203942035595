import React from 'react';
import styled from 'styled-components';
import { Serp } from '../semcore';

type SerpPreviewButtonProps = {
    onClick: () => void;
    tabIndex: number;
}

const SerpPreviewButton = ({ onClick, tabIndex }: SerpPreviewButtonProps) => {
    return (
        <PreviewLink tabIndex={tabIndex} onClick={onClick}>
            <span>Preview</span> <Serp />
        </PreviewLink>
    );
};

export default SerpPreviewButton;

const PreviewLink = styled.a`
    display: flex;
    gap: 5px;
    align-items: center;
    user-select: none;

    span:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;