export enum ErrorCode {
    INVALID_REQUEST = 'invalid-request',
    INVALID_BODY = 'invalid-body',
    INVALID_PARAMS = 'invalid-params',
    INVALID_QUERY = 'invalid-query',
    MISSING_QUERY_PARAM = 'missing-request-param',
    MISSING_REQUEST_BODY = 'missing-request-body',
    UNEXPECTED_VALUE_PROVIDED = 'unexpected value provided',
    NOT_FOUND = 'not-found',
    LIMIT_REACHED = 'limit-reached',
    UNAUTHORIZED_ACCESS = 'unauthorized-access',
    SERVICE_UNREACHABLE = 'service-unreachable',
    CONFLICT = 'conflict',

    CHANNEL_NOT_FOUND = 'channel/not-found',
    CHANNEL_INFO_MISSING = 'channel/info-missing',
    CHANNEL_INVALID_URL = 'channel/invalid-url',
    CHANNEL_MALFORMED_STATS_QUERY = 'channel/malformed-stats-query',
    CHANNEL_UNKNOWN_STATS_METRIC = 'channel/unknown-stats-metric',

    INVALID_KEYWORD_IDENTIFIER = 'rankings/invalid-identifier',
    NO_KEYWORDS_SPECIFIED = 'rankings/no-keywords-specified',
    RANKINGS_MALFORMED_QUERY = 'rankings/malformed-query',

    USER_NOT_FOUND = 'users/not-found',
    MISSING_JWT_HEADER = 'users/missing-jwt-header',
    CORRUPT_USER_TOKEN = 'users/corrupt-token',
    PLAN_MISMATCH = 'users/plan-mismatch',

    INTERNAL_ERROR = 'internal-error',

    RESOURCE_CONFLICT = 'resource-conflict',
    TOO_MANY_REQUESTS = 'too-many-requests',
    INVALID_GRANT = 'invalid-grant',
}