import { Fragment, useMemo } from 'react';
import styled from 'styled-components';
import { Bars, Chart, ErrorChart, StackedBar } from '../../component/chart';
import { useSelectedChannel } from '../../util/channel';
import { useFormatDates, useSubDays, useTomorrow, useWeekBefore } from '../../util/date';
import { useTranslation } from 'react-i18next';
import { RankChange, useKeywordsRank } from '../../core';
import { ArrowDown, ArrowRight, ArrowUp, Skeleton } from '../../semcore';

type KeywordRankProps = {
    data?: RankChange;
    error?: any;
};

export function KeywordRank({ data, error }: KeywordRankProps) {
    const { t } = useTranslation();
    const total = useMemo(() => {
        if (!data) {
            return 0;
        }

        return getTotal(data);
    }, [data]);

    const bars = useMemo(() => {
        if (!data) {
            return [];
        }

        return getBars(data);
    }, [data]);

    if (error !== undefined) {
        return <ErrorChart title={t('keyword_rank.title')} info={t('keyword_rank.info')} />;
    }

    return (
        <Chart title={t('keyword_rank.title')} info={t('keyword_rank.info')}>
            {data === undefined ? (
                <Skeleton h='70'>
                    <rect x='0' y='0' rx='4' ry='4' width='60' height='34' />
                    <rect x='70' y='0' rx='4' ry='4' width='60' height='34' />
                    <rect x='140' y='0' rx='4' ry='4' width='60' height='34' />
                    <rect x='0' y='50' rx='4' ry='4' width='100%' height='16' />
                </Skeleton>
            ) : (
                <Fragment>
                    <Changes>
                        {Object.entries(data).map(([key, value]) => (
                            <Change key={key} value={value} direction={key} />
                        ))}
                    </Changes>
                    <Bars>
                        {bars.map((d) => (
                            <StackedBar key={d.color} color={d.color} width={getWidth(d.value, total)} />
                        ))}
                    </Bars>
                </Fragment>
            )}
        </Chart>
    );
}

export default function KeywordRankContainer() {
    const channelId = useSelectedChannel();
    const tomorrow = useTomorrow();
    const comparison = useSubDays(tomorrow, 7);

    const lastWeek = useWeekBefore(comparison);
    const currentWeek = useWeekBefore(tomorrow);
    const ranges = useFormatDates([...[lastWeek, comparison], ...[currentWeek, tomorrow]]);
    const { data, error } = useKeywordsRank(channelId, ranges);
    return <KeywordRank data={data?.data} error={error} />;
}

type ChangesType = {
    key: string;
    value: number;
    color: string;
};

type KV = {
    [key: string]: number;
};

function getBars(changes: KV): Array<ChangesType> {
    const keys = ['up', 'down', 'stable'];
    return keys.map((key) => {
        const value = changes[key] ?? 0;
        const color = key === 'up' ? 'var(--salad-300)' : key === 'down' ? 'var(--red)' : 'var(--mystic)';

        return { key, value, color };
    });
}

function getTotal(changes: RankChange): number {
    return Object.values(changes).reduce((acc, x) => acc + x, 0);
}

function getWidth(value: number, total: number): number {
    if (total === 0) {
        return 0;
    }

    return (value / total) * 100;
}

const Changes = styled.section`
    display: flex;
    margin-bottom: calc(var(--baseline) * 2);
`;

const StyledChange = styled.div`
    margin-right: calc(var(--baseline) * 2);
    display: flex;
    align-items: center;

    span {
        font-size: calc(var(--baseline) * 3);
        display: inline-block;
        margin-right: calc(var(--baseline) / 2);
    }
`;

type ChangeProps = {
    value: number;
    direction: string;
};

function Change({ value, direction }: ChangeProps) {
    return (
        <StyledChange>
            <span>{value}</span>
            {direction === 'down' && <ArrowDown color='var(--red)' />}
            {direction === 'up' && <ArrowUp color='var(--salad-300)' />}
            {direction === 'stable' && <ArrowRight color='var(--gray70)' />}
        </StyledChange>
    );
}
