import React from 'react';

const EmptyTableIcon = () => {
    return (
        <svg width='100' height='72' viewBox='0 0 100 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd' clipRule='evenodd'
                d='M78 20H22C19.7909 20 18 21.7909 18 24V68C18 70.2091 19.7909 72 22 72H78C80.2091 72 82 70.2091 82 68V24C82 21.7909 80.2091 20 78 20ZM20 24C20 22.8954 20.8954 22 22 22H78C79.1046 22 80 22.8954 80 24V68C80 69.1046 79.1046 70 78 70H22C20.8954 70 20 69.1046 20 68V24Z'
                fill='#CBD1D3'
            />
            <path d='M20 24C20 22.8954 20.8954 22 22 22H78C79.1046 22 80 22.8954 80 24V34H20V24Z' fill='#EEF0F1' />
            <path
                d='M22 52C22 51.4477 22.4477 51 23 51H77C77.5523 51 78 51.4477 78 52C78 52.5523 77.5523 53 77 53H23C22.4477 53 22 52.5523 22 52Z'
                fill='#CBD1D3'
            />
            <rect x='60' y='36' width='2' height='32' rx='1' fill='#CBD1D3' />
            <rect x='38' y='36' width='2' height='32' rx='1' fill='#CBD1D3' />
            <rect x='70' y='26' width='6' height='2' rx='1' fill='#CBD1D3' />
            <rect x='40' y='26' width='26' height='2' rx='1' fill='#CBD1D3' />
        </svg>
    );
};

export default EmptyTableIcon;