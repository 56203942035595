import { MouseEvent, PropsWithChildren } from 'react';
import SemrButton from '@semcore/ui/button';

interface Props {
    disabled?: boolean;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    size?: 'm' | 'l' | undefined;
    use?: 'secondary' | 'primary' | 'tertiary';
    theme?: 'info' | 'success' | 'warning' | 'danger' | 'muted' | 'invert';
    className?: string;
}

export function Button({
    children,
    disabled = false,
    onClick,
    size,
    use = 'secondary',
    theme,
    className = '',
}: PropsWithChildren<Props>) {
    return (
        <SemrButton size={size} theme={theme} use={use} disabled={disabled} onClick={onClick} className={className}>
            {children}
        </SemrButton>
    );
}
