import { useEffect } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { useUser } from '../core';
import { IChannelDto, IUserDto } from '../types';

interface SelectedChannel {
    channelId?: string;
}

export function useSelectedChannel(): string | null {
    const match = useMatch('/dashboard/:channelId/:currentView') as { params: SelectedChannel };

    if (!match) {
        return null;
    }

    if (!match.params.channelId) {
        return null;
    }

    return match.params.channelId;
}

export function useChannelRedirect() {
    const { data: user } = useUser();
    const navigate = useNavigate();
    const channelId = useSelectedChannel();

    useEffect(() => {
        if (channelId !== null) {
            return;
        }
        if (!user) {
            return;
        }

        const [channel] = user.channels;

        if (!channel) {
            if (!user.hasEverConnectedChannel) {
                navigate(`/onboarding`);
            } else {
                navigate(`/empty-channel`);
            }
        } else {
            navigate(`/dashboard/${channel.id}/overview`);
        }
    }, [channelId, user, navigate]);
}

type GetChannel = {
    index: number;
    channel: IChannelDto;
};

export const getChannel = (channelId?: string | null, user?: IUserDto) => (): GetChannel | null => {
    if (!user) {
        return null;
    }

    if (!channelId) {
        return null;
    }

    const index = findIndex(user.channels, channelId);

    if (index === -1) {
        return null;
    }

    const channel = user.channels[index];

    return { index, channel };
};

const findIndex = (channels: Array<IChannelDto>, channelId: string): number => {
    return channels.findIndex(isChannel(channelId));
};

const isChannel = (channelId: string) => (channel: IChannelDto): boolean => {
    return channel.id === channelId;
};
