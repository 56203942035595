import React, { useEffect, useState } from 'react';
import { useData, useNext, usePrev, useProgress } from '@biotic-ui/stepper';
import { Title, Wrapper } from './shared';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, TabPanel, TabPanelItem } from '../semcore';
import ImportYoutube from '../component/import-youtube';
import ImportSemrush from '../component/import-semrush';
import AddKeywordsForm from '../component/add-keywords';
import { useAddAction, useAddKeywords } from '../core';
import { ActionPosition } from '../types';

const Main = styled.div`
    margin-top: calc(var(--baseline) * 5);
    display: grid;
    grid-template-columns: 400px 1fr;
    column-gap: 2rem;
    margin-bottom: calc(var(--baseline) * 10);
`;

export function AddKeywords() {
    const { t } = useTranslation();
    const { data } = useProgress();

    const channel = data?.[0];

    const addKeywords = useAddKeywords(channel.id, [], 'asc', '');
    const onNext = useNext();
    const onPrev = usePrev();
    const [, setData] = useData([]);
    const [tabComponent, setTabComponent] = useState('youtube');
    const addAction = useAddAction();

    useEffect(() => {
        addAction({ action: 'Viewed Keywords', data: { position: ActionPosition.ONBOADING } });
    }, [addAction]);

    async function handleAdd(keywords: Array<string>) {
        addAction({ action: 'Clicked Add Keyword Button', data: { position: ActionPosition.ONBOADING } });
        const addedKeywords = await addKeywords({ keywords });
        setData(addedKeywords);
        onNext && onNext();
    }

    function handleChange(value: string) {
        setTabComponent(value);
    }

    return (
        <Wrapper>
            <header>
                <Title>{t('onboarding.keywords.title', { name: channel.title })}</Title>
            </header>
            <Main>
                <AddKeywordsForm onAdd={handleAdd} />

                <div>
                    <TabPanel onChange={handleChange} value={tabComponent}>
                        <TabPanelItem tabIndex={4} value={'youtube'}>
                            <TabName>{t('onboarding.keywords.youtube_studio_tab')}</TabName>
                        </TabPanelItem>
                        <TabPanelItem tabIndex={5} value={'semrush'}>
                            <TabName>{t('onboarding.keywords.semrush_tab')}</TabName>
                        </TabPanelItem>
                    </TabPanel>
                    {tabComponent === 'youtube' ? <ImportYoutube channelId={channel.id} /> : <ImportSemrush />}
                </div>
            </Main>
            <footer>
                <Button onClick={() => onPrev && onPrev()} size='l'>
                    {t('app.back')}
                </Button>
            </footer>
        </Wrapper>
    );
}

const TabName = styled.span`
    margin: auto 2px;
`;
