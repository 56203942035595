import React from 'react';
import styled from 'styled-components';
import { CheckBox, Info, Skeleton, Table, Tooltip } from '../semcore';
import { useTranslation } from 'react-i18next';
import KeywordResult from './keyword-result';
import { Rank, RankChange } from './rank';
import { EmptyKeywords } from './empty-keywords';
import { SearchVolume } from './search-volume';
import SerpPreviewButton from './serp-preview-button';
import { IYtEntity } from '../types';

export type Row = {
    id: number;
    selected: boolean;
    keyword: string;
    preview: string;
    rank: number;
    change: number;
    videoResult: IYtEntity;
    searchVolume: number;
}
interface Props {
    data: Array<Row | null>;
    isLoading: boolean;
    onSelect: (id: number) => void;
    onSelectAll: () => void;
    selected: Array<any>;
    onAdd: () => void;
    onSort: (s: string) => void;
    sort: keyof Row;
    order: 'desc' | 'asc';
    setSerpKeyword: (k: string) => void;
    showSerp: () => void;
}

const NoOp = () => {};


function getSkeleton() {
  return ['selected', 'keyword', 'preview', 'rank', 'change', 'videoResult', 'searchVolume',].map((c) => ({
    cssVar: `--${c}_width`,
    name: c,
    data: (
      <Skeleton height={17}>
        <Skeleton.Text y='5' width='60%' />
      </Skeleton>
    ),
  }));
}

export function KeywordsTable({
    data,
    isLoading,
    onSelect = NoOp,
    selected,
    onSelectAll,
    onAdd,
    onSort,
    sort,
    order,
    setSerpKeyword,
    showSerp,
}: Props) {
    const { t } = useTranslation();
    return (
        <Table data={data} sort={[sort, order]}>
            <Table.Head>
                <Table.Column
                    name='selected'
                    wMax='50px'
                >
                    <CheckBox checked={selected.length > 0} onChange={() => onSelectAll()} />
                </Table.Column>
                <Table.Column 
                    name='keyword'
                    sortable
                    onClick={() => onSort('value')}
                >
                    {t('keywords.keyword')}
                </Table.Column>
                <Table.Column name='preview' wMax='150px'>
                    {t('keywords.youtube_results')}
                </Table.Column>
                <Table.Column 
                    name='rank'
                    wMax='100px'
                    justifyContent='flex-end'
                    sortable
                    onClick={() => onSort('rank.current')}
                >
                    {t('keywords.current_rank')}
                </Table.Column>
                <Table.Column
                    name='change'
                    wMax='100px'
                    justifyContent='center'
                    sortable
                    onClick={() => onSort('rank.change')}
                >
                    <ChangeHeader>
                        <span>{t('keywords.rank_change')}</span>
                        <Tooltip>
                            <Trigger>
                                <Info color='var(--gray60)' />
                            </Trigger>
                            <Tooltip.Popper>{t('keywords.rank_change_tool_tip').toString()}</Tooltip.Popper>
                        </Tooltip>
                    </ChangeHeader>
                </Table.Column>
                <Table.Column name='videoResult'>
                    {t('keywords.result')}
                </Table.Column>
                <Table.Column
                    name='searchVolume'
                    wMax='100px'
                    justifyContent='flex-end'
                    sortable
                    onClick={() => onSort('searchVolume')}
                >
                    <SVHeader>
                        <span>{t('keywords.search_volume')}</span>
                        <Tooltip>
                            <Trigger>
                                <Info color='var(--gray60)' />
                            </Trigger>
                            <Tooltip.Popper>{t('keywords.search_volume_tooltip').toString()}</Tooltip.Popper>
                        </Tooltip>
                    </SVHeader>
                </Table.Column>
            </Table.Head>

            <Table.Body
                  {...(isLoading ? { rows: [getSkeleton(), getSkeleton(), getSkeleton()] } : {})}
            >
                <Table.Cell data={data} name='selected'>
                    {(_, row) => ({
                        children: (
                            <CheckBox
                                checked={row ? row.selected : false}
                                onChange={() => row && onSelect(row.id)}
                            />
                        )
                    })}
                </Table.Cell>

                <Table.Cell data={data} name='keyword'>
                    {(_, row) => ({
                        children: row.keyword,
                    })}
                </Table.Cell>

                <Table.Cell data={data} name='preview'>
                    {(_, row) => ({
                        children:  <SerpPreviewButton
                            tabIndex={4} 
                            onClick={() => {
                                setSerpKeyword(row.preview);
                                showSerp();
                            }}
                        />
                    })}
                </Table.Cell>

                <Table.Cell data={data} name='rank'>
                    {(_, row) => ({
                        children: <Rank rank={row?.rank} />,
                    })}
                </Table.Cell>
                <Table.Cell data={data} name='change'>
                    {(_, row) => ({
                        children: <RankChange value={row.change} />,
                    })}
                </Table.Cell>
                <Table.Cell data={data} name='videoResult'>
                    {(_, row) => ({
                        children: <KeywordResult youtube={row?.videoResult} />,
                    })}
                </Table.Cell>
                <Table.Cell data={data} name='searchVolume'>
                    {(_, row) => ({
                        children: <SearchVolume value={row?.searchVolume} />,
                    })}
                </Table.Cell>

                {data.length === 0 && (
                    <tr>
                        <EmptyWrapper colSpan={7}>
                            <EmptyKeywords onAdd={() => onAdd()} />
                        </EmptyWrapper>
                    </tr>
                )}
            </Table.Body>
        </Table>
    );
}

const EmptyWrapper = styled.td`
    padding-top: calc(var(--baseline) * 5);
`;

const Trigger = styled(Tooltip.Trigger)`
    display: inline-flex;
    margin-left: calc(var(--baseline) / 4);
`;

const ChangeHeader = styled.span`
    display: inline-flex;
`;

const SVHeader = styled.span`
    display: flex;
    text-align: right;
`;
