import React from 'react';
import styled from 'styled-components';
import { Link, Tooltip } from '../semcore';
import { IYtEntity } from '../types';

type Props = {
    youtube?: IYtEntity | null;
};

export default function KeywordResult({ youtube }: Props) {
    if (!youtube) {
        return <span>-</span>;
    }

    return (
        <Tooltip>
            <Tooltip.Trigger>
                <VideoTitle href={youtube.url} target='_blank' rel='noopener noreferrer'>
                    {youtube.title}
                </VideoTitle>
            </Tooltip.Trigger>
            <Popper p='var(--baseline)'>
                <Thumbnail>
                    <img src={youtube.thumbnail} alt={`${youtube.title} thumbnail`} />
                    <span>{youtube.length}</span>
                </Thumbnail>
                <div>
                    <Title>{youtube.title}</Title>
                    <Description>{youtube.description}</Description>
                </div>
            </Popper>
        </Tooltip>
    );
}

const VideoTitle = styled(Link)`
    color: inherit;
    max-width: 370px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Popper = styled(Tooltip.Popper)`
    max-width: 280px;
    width: 280px;
`;

const Thumbnail = styled.div`
    position: relative;
    display: flex;
    margin-bottom: var(--baseline);

    img {
        width: 100%;
    }

    span {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 4px;
        color: #fff;
        background: rgba(0, 0, 0, 0.8);
        font-size: 12px;
        padding: 4px;
        border-radius: 4px;
    }
`;

const Title = styled.h5`
    margin-bottom: var(--baseline);
`;

const Description = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
`;
