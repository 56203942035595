import React from 'react';
import styled from 'styled-components';
import { Button } from './button';
import { useTranslation } from 'react-i18next';
import { useAddAction } from '../core';
import { ActionPosition } from '../types';

interface Props {
    onDelete: () => void;
    onCancel: () => void;
    onError: () => void;
}

export default function DeleteKeywords({ onDelete, onCancel, onError }: Props) {
    const { t } = useTranslation();
    const addAction = useAddAction();

    async function deleteKeywords() {
        addAction({ action: 'Clicked Delete Keyword Button', data: { position: ActionPosition.DASHBOARD } });
        try {
            await onDelete();
        } catch (error) {
            onError();
            console.log({ error });
        }
    }

    function cancelDelete() {
        onCancel();
    }

    return (
        <div>
            <h3>{t('keywords.delete_multi')}</h3>
            <p>{t('keywords.delete_multi_description')}</p>
            <Footer>
                <Button onClick={deleteKeywords} theme='warning' use='primary' size='m'>
                    {t('app.delete').toString()}
                </Button>
                <Button onClick={cancelDelete} theme='invert' use='primary' size='m'>
                    {t('app.cancel').toString()}
                </Button>
            </Footer>
        </div>
    );
}

const Footer = styled.footer`
    display: flex;
    justify-content: flex-start;

    button {
        margin-right: var(--baseline);
    }
`;
