import { useEffect } from 'react';
import Onboarding from './onboarding';
import Dashboard from './dashboard';
import { Routes, Route, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { EmptyChannel } from './dashboard/empty-channel';
import { useUser } from './core';

function App() {
    const { data: user } = useUser();

    const id = user?.id;
    const navigate = useNavigate();

    useEffect(() => {
        Sentry.setUser({ id });
    }, [id]);

    useEffect(() => {
        if (window.location.pathname.startsWith('/onboarding')) {
            navigate('dashboard');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Routes>
            <Route path="onboarding" element={<Onboarding />} />
            <Route path="empty-channel" element={<EmptyChannel />} />
            <Route path="dashboard/*" element={<Dashboard />} />
            <Route path="*" element={<Dashboard />} />
        </Routes>
    );
}

export default App;
