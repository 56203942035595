import React, { useState } from 'react';
import styled from 'styled-components';
import { Overview } from './overview';
import Keywords from './keywords';
import { useMatch, useNavigate, useRoutes, Outlet } from 'react-router-dom';
import { useChannelRedirect } from '../util/channel';
import { Container } from '../component/layout';
import { useTranslation } from 'react-i18next';
import DashboardErrorBoundary from '../component/error-handling/dashboard-error-boundary';
import DeleteChannel from '../component/delete-channel';
import Header from './component/header';
import { TabPanel, TabPanelItem } from '../semcore';
import { Modal, ModalWrapper } from '../component/modal';
import { Loading, useDeleteChannel, useUser } from '../core';

interface RouteState {
    channelId?: string;
    currentView?: string;
}

export default function Dashboard() {
    useChannelRedirect();

    const { t } = useTranslation();
    const { data: user } = useUser();
    const navigate = useNavigate();
    const match = useMatch('/dashboard/:channelId/:currentView') as { params: RouteState };

    const routes = useRoutes([
        {
            path: '/:selectedChannel/overview',
            element: <Overview />,
        },
        {
            path: '/:selectedChannel/keywords',
            element: <Keywords />,
        },
    ]);

    const [deleteChannel, setDeleteChannel] = useState<string | null | undefined>(null);
    const handleDeleteChannel = useDeleteChannel();

    async function onChannelDelete(channelId: string | null | undefined) {
        try {
            await handleDeleteChannel({ channelId });
            setDeleteChannel(null);
            navigate('/dashboard');
        } catch (err) {
            alert('Error on channel delete');
            console.log({ err });
        }
    }

    if (!match || !match.params.channelId || !user) {
        return <Loading />;
    }

    const selectedChannel = match.params.channelId;

    function handleChange(value: string) {
        navigate(`/dashboard/${selectedChannel}/${value}`);
    }

    return (
        <React.Fragment>
            <Container data-test="dashboard">
                <Header onDelete={setDeleteChannel} />
                <Tabs>
                    <TabPanel onChange={handleChange} value={match.params.currentView || ''}>
                        <TabPanelItem tabIndex={1} value={'overview'}>
                            <TabContent>{t('overview.tab')}</TabContent>
                        </TabPanelItem>
                        <TabPanelItem tabIndex={2} value={'keywords'}>
                            <TabContent>{t('keywords.tab')}</TabContent>
                        </TabPanelItem>
                    </TabPanel>
                </Tabs>
            </Container>
            <Container>
                <DashboardErrorBoundary>
                    <main>
                         {routes}
                        <Outlet />
                    </main>
                </DashboardErrorBoundary>
            </Container>
            <Modal open={!!deleteChannel} onClose={() => setDeleteChannel(null)}>
                <ModalWrapper>
                    <DeleteChannel
                        onDelete={onChannelDelete}
                        onCancel={() => setDeleteChannel(null)}
                        channel={deleteChannel}
                    />
                </ModalWrapper>
            </Modal>
        </React.Fragment>
    );
}

const Tabs = styled.section`
    margin-bottom: calc(var(--baseline) * 3);
`;

const TabContent = styled.span`
    padding: 0 var(--baseline);
`;
