import { useHttp } from '../config';
import { useCallback } from 'react';
import { useUser } from '../user';

const URL = '/api/actions';

export function useAddAction() {
    const axios = useHttp();

    const { data: user } = useUser();
    const addAction = async (data: any) => {
        if (user) {
            data.userId = user.id;
            await axios.post(URL, { ...data });
        }
    };

    return useCallback(addAction, [axios, user]);
}
