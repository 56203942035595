type Column = {
    title: string;
    prop: string;
};

type Config<T> = {
    columns?: (obj: T) => Array<Column>;
};

const defaultConf = {
    columns: (obj: Object) =>
        Object.keys(obj).map((key) => {
            return { title: key, prop: key };
        }),
};

export function formatCSV<T = any>(arr: Array<T>, userConf: Config<T> = {}): string {
    const conf = { ...defaultConf, ...userConf };

    if (arr.length === 0) {
        return '';
    }

    const columns = conf.columns(arr[0]);

    const header = () => {
        return columns.map((col) => col.title).join(',');
    };

    const body = (arr: Array<T>): string => {
        return arr
            .map((obj: any) => columns.map((col) => getValue(obj, col.prop) ?? '').join(','))

            .join('\n');
    };

    const content = `${header()}\n${body(arr)}`;

    const computedCSV = new Blob([content], {
        type: 'text/csv;charset=utf-8',
    });

    return window.URL.createObjectURL(computedCSV);
}

export function formatXLS<T = any>(arr: Array<T>, title: string, userConf: Config<T> = {}) {
    const conf = { ...defaultConf, ...userConf };

    if (arr.length === 0) {
        return '';
    }

    const columns = conf.columns(arr[0]);

    const header = () => {
        return columns.map((col) => `<td>${col.title}</td>`).join('\n');
    };

    const body = (arr: Array<T>): string => {
        return arr
            .map((obj: any) => columns.map((col) => `<td>${getValue(obj, col.prop) ?? ''}</td>`).join('\n'))
            .map((line) => `<tr>${line}</tr>`)
            .join('\n');
    };

    const file = `
<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
<head>
	<meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"/>
	<!--[if gte mso 9]>
		<xml>
		<x:ExcelWorkbook>
			<x:ExcelWorksheets>
				<x:ExcelWorksheet>
					<x:Name>${title}</x:Name>
					<x:WorksheetOptions>
						<x:DisplayGridlines/>
					</x:WorksheetOptions>
				</x:ExcelWorksheet>
			</x:ExcelWorksheets>
		</x:ExcelWorkbook>
		</xml>
	<![endif]-->
</head>
<body>
	<table>
		<tr>
			${header()}
		</tr>
		${body(arr)}
	</table>
</body>
</html>`;
    const computedXLS = new Blob([file], {
        type: 'application/vnd.ms-excel;base64,',
    });

    return window.URL.createObjectURL(computedXLS);
}

export function downloadFile(output: string, fileName: string) {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = fileName;
    link.href = output;
    link.click();
}

export enum ExportType {
    CSV,
    XLS,
}

export enum ExportExt {
    CSV = 'csv',
    XLS = 'xls',
}

function getValue(obj: Object, path: string): unknown | undefined {
    return path.split('.').reduce((acc: any, field) => {
        if (acc === undefined || acc === null) {
            return undefined;
        }

        return acc[field];
    }, obj);
}
