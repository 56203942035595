import React from 'react';
import styled from 'styled-components';
import { DateRange } from './date-range';
import { Info, Tooltip, Warning } from '../semcore';

export const Charts = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fit, 358px);
    grid-column-gap: calc(var(--baseline) * 3);
    grid-row-gap: calc(var(--baseline) * 3);
    justify-content: center;
    width: 100%;
    margin-bottom: calc(var(--baseline) * 3);
`;

const ChartHeader = styled.header`
    display: flex;
    align-items: baseline;
    margin-bottom: calc(var(--baseline) * 0.5);

    h4 {
        margin-bottom: 0;
        margin-right: calc(var(--baseline) / 2);
    }
`;

const StyledChart = styled.div`
    border: 1px solid var(--gray80);
    padding: calc(var(--baseline) * 3);
    padding-top: calc(var(--baseline) * 2.5);
    border-radius: calc(var(--baseline) / 2);
`;

type ChartProps = ChartInfoProps & {
    title: string;
    children?: JSX.Element | Array<JSX.Element>;
};

export function Chart({ title, info, children }: ChartProps) {
    return (
        <StyledChart>
            <ChartHeader>
                <h4>{title}</h4>
                <ChartInfo info={info} />
            </ChartHeader>
            <DateRange />
            <div>{children}</div>
        </StyledChart>
    );
}

export function getColor(key: string): string {
    switch (key) {
        case 'top3':
            return 'var(--denim-blue)';

        case 'top10':
            return 'var(--light-blue)';

        case 'top20':
            return 'var(--cyan)';

        default:
            return 'var(--gray70)';
    }
}

export const Bar = styled.div<{ width: number | string }>`
    height: calc(var(--baseline) * 2);
    width: 100%;
    background: var(--mystic);
    position: relative;

    &:after {
        content: '';
        position: absolute;
        height: calc(var(--baseline) * 2);
        left: 0;
        width: ${(p) => p.width}%;
        background: var(--salad-300);
    }
`;

export const Bars = styled.div`
    height: calc(var(--baseline) * 2);
    width: 100%;
    display: flex;
    background: var(--mystic);
`;

export const StackedBar = styled.div<{ width: number; color: string }>`
    width: ${(p) => p.width}%;
    background: ${(p) => p.color};
`;

type ErrorProps = ChartInfoProps & { title: string };

const ErrorTitle = styled.h5`
    font-size: 19px;
    margin: 0;
    margin-right: var(--baseline);
`;

const ErrorHeader = styled.div`
    border: 1px solid var(--gray80);
    padding: calc(var(--baseline) * 2);
    border-top-left-radius: calc(var(--baseline) / 2);
    border-top-right-radius: calc(var(--baseline) / 2);
    border-bottom: 0;
    height: 56px;
    display: flex;
`;

const ErrorContentTitle = styled.p`
    font-size: 14px;
    margin-bottom: 0;
`;

const ErrorContentInfo = styled.small`
    font-size: 12px;
`;

const StyledErrorContent = styled.div`
    border: 1px solid var(--orange);
    background: linear-gradient(0deg, rgba(249, 177, 89, 0.15), rgba(249, 177, 89, 0.15)), #ffffff;
    padding: calc(var(--baseline) * 2);
    border-bottom-left-radius: calc(var(--baseline) / 2);
    border-bottom-right-radius: calc(var(--baseline) / 2);
    display: flex;
    flex-grow: 1;
`;

const WarningWrapper = styled.div`
    margin-right: var(--baseline);
`;

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export function ErrorContent() {
    return (
        <StyledErrorContent>
            <WarningWrapper>
                <Warning />
            </WarningWrapper>
            <div>
                <ErrorContentTitle>Something went wrong.</ErrorContentTitle>
                <ErrorContentInfo>
                    It looks like there’s not enough data, or something is broken. We’ll fix it.
                </ErrorContentInfo>
            </div>
        </StyledErrorContent>
    );
}

export function ErrorChart({ title, info }: ErrorProps) {
    return (
        <ErrorWrapper>
            <ErrorHeader>
                <ErrorTitle>{title}</ErrorTitle>
                <ChartInfo info={info} />
            </ErrorHeader>
            <ErrorContent />
        </ErrorWrapper>
    );
}

const StyledInfo = styled.span`
    svg {
        fill: var(--stone);
    }
`;

type ChartInfoProps = {
    info?: string;
};

export function ChartInfo({ info }: ChartInfoProps) {
    if (!info) {
        return null;
    }

    return (
        <Tooltip>
            <Tooltip.Trigger>
                <StyledInfo>
                    <Info />
                </StyledInfo>
            </Tooltip.Trigger>
            <Tooltip.Popper>{info}</Tooltip.Popper>
        </Tooltip>
    );
}
