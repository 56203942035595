import React from 'react';
import SemrCheckBox from '@semcore/ui/checkbox';

interface Props {
    checked: boolean;
    onChange: (checked: boolean, e?: React.SyntheticEvent<HTMLInputElement>) => void;
}

export function CheckBox({ checked, onChange }: Props) {
    return (
        <SemrCheckBox>
            <SemrCheckBox.Value size="l" checked={checked} onChange={onChange} />
        </SemrCheckBox>
    );
}
