import { SyntheticEvent, useMemo } from 'react';
import styled from 'styled-components';
import { useMatch, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../../component/button';
import { getChannel } from '../../util/channel';
import { getSemrushSDK, Methods, useAddAction, useUser } from '../../core';
import { SelectValue } from '@semcore/ui/select';
import { Cart, Global, Link, MathPlus, Select, Trash, YoutubeLogo } from '../../semcore';
import { ActionPosition } from '../../types';

interface RouterState {
    channelId?: string;
    currentView?: string;
}

interface Props {
    onDelete: (channel: string | null | undefined) => void;
}

export default function DashboardHeader({ onDelete }: Props) {
    const { t } = useTranslation();
    const { data: user } = useUser();
    const match = useMatch('/dashboard/:channelId/:currentView') as { params: RouterState };
    const navigate = useNavigate();
    const getCurrent = useMemo(() => getChannel(match?.params?.channelId, user), [user, match]);
    const addAction = useAddAction();
    const semrush = getSemrushSDK();

    const handleChannelChange = (value: SelectValue) => {
        if (!match) {
            return;
        }
        const { currentView = 'overview' } = match.params;
        navigate(`/dashboard/${value}/${currentView}`);
    };

    function handleAdd() {
        addAction({ action: 'Clicked Add Channel Button', data: { position: ActionPosition.DASHBOARD } });
        navigate('/onboarding');
    }

    const handleDelete = (id: string | null | undefined) => (e: SyntheticEvent) => {
        e.stopPropagation();
        if (!!id) {
            onDelete(id);
        }
    };

    const handleUpdateClick = async () => {
        addAction({ action: 'Clicked buy more keywords link', data: { position: ActionPosition.DASHBOARD } });
        await semrush.client<[], void>(Methods.RequestInAppPurchase);
    };

    if (match === null || getCurrent === null || !user) {
        return null;
    }

    return (
        <Header>
            <Infos>
                <Info>
                    <span>{t('app.source')}: </span>
                    <Youtube>
                        <YoutubeLogo /> {t('app.youtube')}
                    </Youtube>
                </Info>
                <Divider />
                <Info>
                    {t('app.location')}:
                    <Location>
                        <Global color='currentColor' /> {t('app.global')}
                    </Location>
                </Info>
                <Divider />
                <Info style={{ marginRight: '12px' }}>
                    {t('app.keywords_available')}: {user.keywords.total}/{user.keywords.limit}
                </Info>
                <Info>
                    <UpgradeLink onClick={handleUpdateClick}>
                        <Cart />&nbsp;
                        {t('app.upgradePlan')}
                    </UpgradeLink>
                </Info>
            </Infos>
            <Select tabIndex={3} value={getCurrent()?.channel.id} onChange={handleChannelChange}>
                <Select.Trigger>
                    <Select.Trigger.Text>{getCurrent()?.channel.title}</Select.Trigger.Text>
                </Select.Trigger>
                <Select.Popper>
                    <Select.List hMax='240px'>
                        {user.channels.map((channel) => (
                            <Select.Option key={channel.id} value={channel.id || ''}>
                                <ListItem>
                                    <span>{channel.title}</span>
                                    <IconButton onClick={handleDelete(channel.id)}>
                                        <Trash />
                                    </IconButton>
                                </ListItem>
                            </Select.Option>
                        ))}
                    </Select.List>
                    <AddChannel onClick={handleAdd}>
                        <MathPlus /> <span>{t('app.add_channel')}</span>
                    </AddChannel>
                </Select.Popper>
            </Select>
        </Header>
    );
}

const Header = styled.header`
    padding-bottom: calc(var(--baseline) * 2);
    margin-bottom: calc(var(--baseline) * 2);
    display: flex;
    justify-content: space-between;
`;

const Infos = styled.section`
    display: flex;
    align-items: center;
`;

const Info = styled.span`
    font-size: 0.75rem;
    display: flex;
    align-items: center;
`;

const Divider = styled.div`
    margin: 0 calc(var(--baseline) * 2);
    width: 1px;
    background: var(--gray80);
    height: 1.5rem;
`;

const UpgradeLink = styled(Link)`
    span {
        display: inline-flex;
    }
;
    line-height: 1.5;
`;

const ListItem = styled.div`
    display: flex;
    width: 100%;

    span {
        flex-grow: 1;
    }

    ${IconButton} {
        display: flex;
        opacity: 0;
        align-items: center;
        height: 100%;
        flex-shrink: 0;
        cursor: pointer;
        color: var(--gray20);
        margin-right: calc(var(--baseline) * -1);
    }

    ${IconButton}:hover {
        color: var(--gray60);
    }

    &:hover ${IconButton} {
        opacity: 1;
    }
`;

const AddChannel = styled.button`
    background: none;
    border: none;
    display: flex;
    color: var(--light-blue);
    align-items: center;
    padding: calc(var(--baseline) / 2) var(--baseline);
    margin-bottom: calc(var(--baseline) / 2);
    font-size: 0.75em;
    width: 100%;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    svg {
        margin-right: calc(var(--baseline) / 2);
    }
`;

const Location = styled.div`
    display: inline-flex;
    align-items: center;

    svg {
        margin: 0 calc(var(--baseline) / 2);
    }
`;

const Youtube = styled.div`
    display: inline-flex;
    align-items: center;

    svg {
        margin: 0 calc(var(--baseline) / 2);
    }
`;
