import { SyntheticEvent } from 'react';
import SemrTabPanel from '@semcore/ui/tab-panel';

interface Props {
    onChange: (value: string, e?: SyntheticEvent<HTMLButtonElement, Event>) => void;
    value: string;
    children?: JSX.Element | Array<JSX.Element>;
}

export function TabPanel({ value, onChange, children }: Props) {
    return (
        <SemrTabPanel value={value} onChange={onChange}>
            {children}
        </SemrTabPanel>
    );
}

interface TabItemProps {
    value: any;
    tabIndex: number;
    children?: JSX.Element | Array<JSX.Element> | string;
}

export function TabPanelItem({ value, tabIndex, children }: TabItemProps) {
    return <SemrTabPanel.Item tabIndex={tabIndex} value={value}>{children}</SemrTabPanel.Item>;
}
