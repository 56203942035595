import { useEffect } from 'react';
import styled from 'styled-components';
import { atom, useRecoilState } from 'recoil';

export const titleState = atom<JSX.Element[] | JSX.Element | null | undefined>({
    key: 'textState',
    default: null,
});

interface Props {
    children?: JSX.Element[] | JSX.Element;
}

export function PageTitle({ children }: Props) {

    const [, setTitle] = useRecoilState(titleState);

    useEffect(() => {
        setTitle(children);
    }, [children, setTitle]);

    return null;
}

export const Title = styled.h2`
    font-size: 1.4rem;
    color: white;
`;