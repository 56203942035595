import { SyntheticEvent } from 'react';
import SemrInput from '@semcore/ui/input';

interface Props {
    onChange: (value: string, e?: SyntheticEvent<HTMLInputElement>) => void;
    type?: string;
    name?: string;
    value?: string;
    size: 'm' | 'l';
}

export function Input({ size = 'l', ...props }: Props) {
    return (
        <SemrInput size={size}>
            <SemrInput.Value {...props} />
        </SemrInput>
    );
}
