import React, { useMemo } from 'react';
import { Bar, Chart, ErrorChart } from '../../component/chart';
import styled from 'styled-components';
import { useFormatDates, useSubDays, useTomorrow, useWeekBefore } from '../../util/date';
import { useSelectedChannel } from '../../util/channel';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '../../semcore';
import { useAveragePosition } from '../../core';

type ChartProps = {
    data?: number;
    error?: any;
};

export function AvgPosition({ data, error }: ChartProps) {
    const { t } = useTranslation();
    const rank = useMemo<string | number>(() => {
        if (!data) {
            return 'n/a';
        }

        return data;
    }, [data]);

    if (error !== undefined) {
        return <ErrorChart title={t('avg_position.title')} info={t('avg_position.info')} />;
    }

    return (
        <Chart title={t('avg_position.title')} info={t('avg_position.info')}>
            {data === undefined ? (
                <Skeleton h='60'>
                    <rect x='0' y='0' rx='4' ry='4' width='20%' height='34' />
                    <rect x='0' y='50' rx='4' ry='4' width='100%' height='16' />
                </Skeleton>
            ) : (
                <React.Fragment>
                    <Rank>{rank}</Rank>
                    <Bar width={data > 20 || !data ? 0 : toPercent(1 - data / 20)} />
                    <Lables>
                        <Label>20</Label>
                        <Label>1</Label>
                    </Lables>
                </React.Fragment>
            )}
        </Chart>
    );
}

export default function AvgPositionContainer() {
    const channelId = useSelectedChannel();
    const tomorrow = useTomorrow();
    const comparison = useSubDays(tomorrow, 7);

    const lastWeek = useWeekBefore(comparison);
    const currentWeek = useWeekBefore(tomorrow);
    const ranges = useFormatDates([...[lastWeek, comparison], ...[currentWeek, tomorrow]]);

    const { data, error } = useAveragePosition(channelId, ranges);
    return <AvgPosition data={data?.data} error={error} />;
}

const Rank = styled.span`
    font-size: calc(var(--baseline) * 3);
    margin-bottom: calc(var(--baseline) * 2);
    display: block;
`;

const Lables = styled.section`
    display: flex;
    justify-content: space-between;
    margin-top: calc(var(--baseline) / 2);
`;

const Label = styled.span`
    font-size: calc(var(--baseline) * 1.5);
`;

function toPercent(number: number): number {
    return number * 100;
}
