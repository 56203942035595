import React, { FormEvent, useState } from 'react';
import styled from 'styled-components';
import { Button } from './button';
import { useTranslation } from 'react-i18next';
import { Limit } from './limit';
import { Textarea } from './textarea';
import { IconClose, Tooltip, Warning } from '../semcore';
import { ErrorTitle, Notice } from './notice';
import { useSelectedChannel } from '../util/channel';
import { KeywordError, useAddAction, useKeywordLimit, useKeywordLimits } from '../core';
import { ActionPosition } from '../types';

interface Props {
    onAdd: (k: Array<string>) => Promise<any>;
}

export default function AddKeywords({ onAdd }: Props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [keywords, setKeywords] = useKeywordLimits({ saving: loading });
    const addAction = useAddAction();
    const limit = useKeywordLimit();
    const channel = useSelectedChannel();

    async function handleAdd(e: FormEvent) {
        e.preventDefault();

        addAction({ action: 'Clicked Add Keyword Button', data: { position: ActionPosition.KEYWORDS } });
        setLoading(true);
        try {
            await onAdd(keywords.value);
            addAction({
                action: 'semrush_add_keywords',
                data: {
                    position: ActionPosition.KEYWORDS,
                    channelYtId: channel,
                    keywords: keywords.value,
                },
            });
            setKeywords('');
        } catch (err) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const label = loading ? t('app.loading') + '...' : t('onboarding.keywords.import_keywords');
    const addedKeywords = keywords.value.length;
    const limitError = limit.total + addedKeywords > limit.limit;
    const disabled = loading || limitError || addedKeywords === 0;
    const charLimitError = keywords.errors.find((error) => {
        return error.type === KeywordError.CharLimit;
    });

    return (
        <div>
            <KeywordsTitle>
                {t('onboarding.keywords.enter_keywords', { limit: limit.limit })}{' '}
                <Limit addedKeywords={addedKeywords} />
            </KeywordsTitle>
            <Form onSubmit={handleAdd}>
                <Tooltip>
                    <Tooltip.Trigger>
                        <StyledTexarea
                            error={charLimitError !== undefined}
                            onChange={(e: string) => setKeywords(e)}
                            cols={30}
                            rows={10}
                            value={keywords.text}
                        />
                        <Popper theme='warning' visible={charLimitError !== undefined}>
                            <p>{t('keywords.error_limit')}</p>
                            <ol>
                                {charLimitError?.type === KeywordError.CharLimit &&
                                    charLimitError?.error.map((err) => {
                                        return (
                                            <li>
                                                {t('keywords.line')} {err.index + 1}: {err.keyword}
                                            </li>
                                        );
                                    })}
                            </ol>
                        </Popper>
                    </Tooltip.Trigger>
                </Tooltip>

                {limitError && <ErrorMessage>You can’t enter more than {limit.limit} keywords</ErrorMessage>}

                <Actions>
                    <Button disabled={disabled} size='l' use='primary' theme='success' onClick={handleAdd}>
                        {label}
                    </Button>
                    <ClearButton>
                        <Button size='m' use='primary' theme='invert' onClick={() => setKeywords('')}>
                            <IconClose color='#0071BC' />
                            Clear
                        </Button>
                    </ClearButton>
                </Actions>
            </Form>
            {error && (
                <Notice theme='warning'>
                    <Notice.Label>
                        <Warning />
                    </Notice.Label>
                    <Notice.Content>
                        <ErrorTitle>{t('error.generic_title')}</ErrorTitle>
                        <span>{t('keywords.error')}</span>
                    </Notice.Content>
                    <Notice.CloseIcon onClick={() => setError(false)} />
                </Notice>
            )}
        </div>
    );
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-bottom: calc(var(--baseline) * 3);

    textarea {
    }
`;

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    > Button {
        width: 40%;
    }

    > a {
        color: #0071bc;
        cursor: pointer;
    }
`;

const ClearButton = styled.span`
    > Button {
        color: #0071bc !important;
    }
`;

const KeywordsTitle = styled.div`
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(var(--baseline) * 2);
    align-items: center;
`;

const StyledTexarea = styled(Textarea)<{ error: boolean }>`
    border-color: ${(p) => (p.error ? 'var(--orange)' : 'inherit')};

    &:focus {
        box-shadow: none !important;
        border-color: ${(p) => (p.error ? 'var(--orange)' : 'inherit')} !important;
    }
`;

const Popper = styled(Tooltip.Popper)`
    ol {
        padding: 0;
        list-style-type: none;
    }

    ol li {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: var(--baseline);
    }

    ol li:last-child {
        margin-bottom: 0;
    }
`;

const ErrorMessage = styled.p`
    color: var(--orange);
    margin-bottom: 0;
`;
