import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelectedChannel } from '../../util/channel';
import { ErrorContent } from '../../component/chart';
import { EmptyKeywords } from '../../component/empty-keywords';
import { Rank, RankChange } from '../../component/rank';
import { useFormatDates, useSubDays, useTomorrow, useWeekBefore } from '../../util/date';
import { SearchVolume } from '../../component/search-volume';
import KeywordResult from '../../component/keyword-result';
import SerpPreviewButton from '../../component/serp-preview-button';
import { useTopKeywords } from '../../core';
import { Info, Skeleton, Table, Tooltip } from '../../semcore';

type Props = {
    setSerpKeyword: Dispatch<SetStateAction<string>>;
    showSerp: () => void;
}

function getSkeleton() {
  return ['keyword', 'preview', 'rank', 'change', 'videoResult', 'searchVolume',].map((c) => ({
    cssVar: `--${c}_width`,
    name: c,
    data: (
      <Skeleton height={17}>
        <Skeleton.Text y='5' width='60%' />
      </Skeleton>
    ),
  }));
}

export function TopKeywords({ setSerpKeyword, showSerp }: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const channelId = useSelectedChannel();

    const tomorrow = useTomorrow();
    const comparison = useSubDays(tomorrow, 7);
    const lastWeek = useWeekBefore(comparison);
    const currentWeek = useWeekBefore(tomorrow);
    const ranges = useFormatDates([...[lastWeek, comparison], ...[currentWeek, tomorrow]]);

    const { data: rankings, error, isLoading } = useTopKeywords(channelId, ranges);

    function handleAdd() {
        navigate(`/dashboard/${channelId}/keywords?add=true`);
    }

    const tableData = !rankings ? [] : rankings.map(ranking => ({
        keyword: ranking.value,
        preview: ranking.value,
        rank: ranking?.rank?.current,
        change: ranking?.rank?.change,
        videoResult: ranking?.currentResult?.entity,
        searchVolume: ranking?.searchVolume,
    }))

    return (
        <Table data={tableData} sort={['rank', 'asc']}>
            <Table.Head>
                <Table.Column name='keyword'>
                    {t('keywords.keyword')}
                </Table.Column>
                <Table.Column name='preview' wMax='150px'>
                    {t('keywords.youtube_results')}
                </Table.Column>
                <Table.Column name='rank' wMax='80px' justifyContent='flex-end' sortable>
                    {t('keywords.current_rank')}
                </Table.Column>
                <Table.Column name='change' wMax='80px' justifyContent='center'>
                    <ChangeHeader>
                        <span>{t('keywords.rank_change')}</span>
                        <Tooltip>
                            <Trigger>
                                <Info color='var(--gray60)' />
                            </Trigger>
                            <Tooltip.Popper>{t('keywords.rank_change_tool_tip').toString()}</Tooltip.Popper>
                        </Tooltip>
                    </ChangeHeader>
                </Table.Column>
                <Table.Column name='videoResult'>
                    {t('keywords.result')}
                </Table.Column>
                <Table.Column name='searchVolume' wMax='80px' justifyContent='flex-end'>
                    <SVHeader>
                        <span>{t('keywords.search_volume')}</span>
                        <Tooltip>
                            <Trigger>
                                <Info color='var(--gray60)' />
                            </Trigger>
                            <Tooltip.Popper>{t('keywords.search_volume_tooltip').toString()}</Tooltip.Popper>
                        </Tooltip>
                    </SVHeader>
                </Table.Column>
            </Table.Head>
            <Table.Body 
                {...(isLoading ? { rows: [getSkeleton(), getSkeleton(), getSkeleton()] } : {})}
            >
                <Table.Cell data={tableData} name='keyword'>
                    {(_, row) => ({
                        children: row.keyword,
                    })}
                </Table.Cell>
                <Table.Cell data={tableData} name='preview'>
                    {(_, row) => ({
                        children:  <SerpPreviewButton
                            tabIndex={4} 
                            onClick={() => {
                                setSerpKeyword(row.preview);
                                showSerp();
                            }}
                        />
                    })}
                </Table.Cell>
                <Table.Cell data={tableData} name='rank'>
                    {(_, row) => ({
                        children: <Rank rank={row?.rank} />,
                    })}
                </Table.Cell>
                <Table.Cell data={tableData} name='change'>
                    {(_, row) => ({
                        children: <RankChange value={row.change} />,
                    })}
                </Table.Cell>
                <Table.Cell data={tableData} name='videoResult'>
                    {(_, row) => ({
                        children: <KeywordResult youtube={row?.videoResult} />,
                    })}
                </Table.Cell>
                <Table.Cell data={tableData} name='searchVolume'>
                    {(_, row) => ({
                        children: <SearchVolume value={row?.searchVolume} />,
                    })}
                </Table.Cell>

                {error && (
                    <tr>
                        <td colSpan={6}>
                            <ErrorContent />
                        </td>
                    </tr>
                )}

                {!error && tableData.length === 0 && (
                    <tr>
                        <EmptyWrapper colSpan={6}>
                            <EmptyKeywords onAdd={() => handleAdd()} />
                        </EmptyWrapper>
                    </tr>
                )}
            </Table.Body>
        </Table>
    );
}

const EmptyWrapper = styled.td`
    padding-top: calc(var(--baseline) * 5);
`;

const ChangeHeader = styled.span`
    display: inline-flex;
`;

const SVHeader = styled.span`
    display: inline-flex;
`;

const Trigger = styled(Tooltip.Trigger)`
    display: inline-flex;
    margin-left: calc(var(--baseline) / 4);
`;
