export function getShortNumberFormat(number: number, decimals = 1) {
    const SI_SYMBOL = ["", "K", "M", "G", "T", "P", "E"];

    const tier = Math.log10(Math.abs(number)) / 3 | 0;

    if (tier === 0) return number;

    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);
    const scaled = number / scale;
    const textNr = scaled.toFixed(decimals).substr(-decimals) === '0'.repeat(decimals) ?
        scaled.toFixed(0) :
        scaled.toFixed(1)

    return `${textNr} ${suffix}`;
}