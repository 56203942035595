import { createContext, useContext, useEffect, useState } from 'react';
import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import { useConfig } from '../config';

export interface ISegment {
    page: any;
    track: any;
    identify: any;
    reset: any;
}

export type SegmentState = ISegment | null;

export const SegmentContext = createContext<SegmentState>(null);

export function useInitSegment(): SegmentState {
    const { appId, segment } = useConfig();
    const [analytics, setAnalytics] = useState<SegmentState>(null);
    const config = useConfig();

    useEffect(() => {
        if (config.nodeEnv === 'development') {
            return;
        }

        const analytics = Analytics({
            app: appId,
            plugins: [
                segmentPlugin({
                    writeKey: segment.apiKey,
                }),
            ],
        });
        setAnalytics(analytics);
    }, [appId, config.nodeEnv, segment]);

    return analytics;
}

export function useSegment(): SegmentState {
    return useContext(SegmentContext);
}
