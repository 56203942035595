import styled from 'styled-components';

export const Wrapper = styled.section`
    padding-top: 1rem;
    max-width: 1200px;
    width: 90%;
`;

export const Title = styled.h2`
    text-align: center;
    margin-bottom: calc(var(--baseline) * 5);
`;

export const Text = styled.p`
    margin-bottom: var(--baseline);
`;
