import React from 'react';

const WarningIcon = () => {
    return (
        <svg width='100' height='72' viewBox='0 0 100 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M76.6666 71.9999C79.6602 71.9999 80.8602 69.6415 79.9999 67.0312L53.3332 20.6562C51.1969 18.336 48.8029 18.336 46.6666 20.6562L19.9999 67.0312C19.1397 69.9359 20.3396 71.9999 23.3332 71.9999H76.6666ZM51.7175 21.8585L78.1556 67.8359C78.5584 69.2399 78.0336 69.9999 76.6666 69.9999H23.3332C21.8782 69.9999 21.4348 69.4061 21.8574 67.8129L48.2823 21.8585C49.5095 20.6019 50.4903 20.6019 51.7175 21.8585Z'
                fill='#CBD1D3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M36.445 64.2507H33.0452C31.9451 64.2507 31.562 63.7059 31.8814 62.5238L48.4988 33.6251C49.389 32.7021 50.1758 32.7021 51.0661 33.6251L67.6905 62.536C68.0035 63.5926 67.5674 64.2507 66.5196 64.2507H47.4632C46.9109 64.2507 46.4632 64.6984 46.4632 65.2507C46.4632 65.803 46.9109 66.2507 47.4632 66.2507H66.5196C69.0373 66.2507 70.3322 64.1577 69.5615 61.8191L69.4787 61.6336L52.7415 32.5266L52.6102 32.3477C50.8732 30.4612 48.6916 30.4612 46.9546 32.3477L46.8234 32.5266L30.0862 61.6336C30.0473 61.7013 30.0164 61.7733 29.9942 61.8481C29.2608 64.3248 30.5022 66.2507 33.0452 66.2507H36.445C36.9972 66.2507 37.445 65.803 37.445 65.2507C37.445 64.6984 36.9972 64.2507 36.445 64.2507ZM39.605 65.2507C39.605 65.803 40.0527 66.2507 40.605 66.2507H43.987C44.5393 66.2507 44.987 65.803 44.987 65.2507C44.987 64.6984 44.5393 64.2507 43.987 64.2507H40.605C40.0527 64.2507 39.605 64.6984 39.605 65.2507Z'
                fill='#CBD1D3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M50 43C48.8954 43 48 43.8954 48 45V51C48 52.1046 48.8954 53 50 53C51.1046 53 52 52.1046 52 51V45C52 43.8954 51.1046 43 50 43ZM52 57C52 58.1046 51.1046 59 50 59C48.8954 59 48 58.1046 48 57C48 55.8954 48.8954 55 50 55C51.1046 55 52 55.8954 52 57Z'
                fill='#CBD1D3'
            />
        </svg>
    );
};

export default WarningIcon;