import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '../../semcore';
import { useNavigate } from 'react-router';

export default function ConnectChannelHeader() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function connectChannel() {
        navigate('/onboarding');
    }

    return (
        <Header>
            <Section>
                <Title>{t('empty_channel.header')}</Title>
                <Button use='primary' onClick={connectChannel}>
                    {t('empty_channel.button')}
                </Button>
            </Section>
        </Header>
    );
}

const Header = styled.header`
    width: 100%;
    padding: calc(var(--baseline) * 2);
    margin-bottom: calc(var(--baseline) * 2);
    border-bottom: 1px solid var(--gray80);
`;

const Section = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--baseline);
`;

const Title = styled.h2`
    margin-bottom: 0;
    margin-right: var(--baseline);
    flex-shrink: 0;
`;
