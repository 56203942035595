import React, { FunctionComponent } from 'react';
import SemrModal from '@semcore/ui/modal';

interface Props {
    children: JSX.Element | Array<JSX.Element>;
    open: boolean;
    mt?: number;
    onClose?: (
        trigger: 'onOutsideClick' | 'onCloseClick' | 'onEscape',
        e?: React.MouseEvent | React.KeyboardEvent
    ) => void;
    className?: string;
    hMin?: string;
}

export const Modal: FunctionComponent<Props> = ({ children, open, onClose, mt = 0, className = '', hMin }) => {
    return (
        <SemrModal visible={open} onClose={onClose} className={className}>
            <SemrModal.Overlay style={{ background: 'rgba(255, 255, 255, .75)' }}>
                <SemrModal.Window
                    px={5}
                    py={2.5}
                    mt={mt}
                    hMin={hMin}
                    style={{ boxShadow: '0 3px 5px 0 rgba(0,0,0,0.2)' }}>
                    {children}
                </SemrModal.Window>
            </SemrModal.Overlay>
        </SemrModal>
    );
};
