import React, { Fragment } from 'react';
import { Navbar } from './navbar';
import { limitWidth } from '../styles';
import styled from 'styled-components';

const Main = styled.main`
    ${limitWidth}
`;

interface Props {
    children?: JSX.Element[] | JSX.Element;
}

/**
 * The base-component for tubics micro-apps. Includes the tubics navigation bar.
 */
export function BaseLayout({ children }: Props) {
    return (
        <Fragment>
            <Navbar />
            <Main role='main'>
                {children}
            </Main>
        </Fragment>
    );
}
