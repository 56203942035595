import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { subDays, format } from 'date-fns';

function formatDate(date: Date): string {
    return format(date, 'd.MMM');
}

export function DateRange() {
    const [date] = useState(() => {
        return new Date();
    });

    const firstDate = useMemo(() => {
        return formatDate(subDays(date, 8));
    }, [date]);

    const lastDate = useMemo(() => {
        return formatDate(subDays(date, 1));
    }, [date]);

    return (
        <Dates>
            From {firstDate} - {lastDate}
        </Dates>
    );
}

const Dates = styled.div`
    color: #aaaaaa;
    font-size: 12px;
    margin-bottom: calc(var(--baseline) * 2);
`;
