import { useCallback, useEffect, useRef, useState } from 'react';
import { useHttp } from '../config';
import { addChannel as addUserChannel, removeChannel, UserKey } from '../user';
import { mutate } from 'swr';
import _ from 'lodash';
import { isYTURL } from './channel.utils';
import { AddChannelBody, DeleteChannelBody, IChannelDto } from '../../types';

const URL = '/api/channels';

/*
 * useAddChannel returns a function that adds a new channel to a user
 */
export function useAddChannel() {
    const axios = useHttp();

    const addChannel = async ({ channel }: AddChannelBody): Promise<IChannelDto | null> => {
        if (channel === '') {
            return null;
        }

        const { data: addedChannel } = await axios.post(URL, { channel });
        mutate(UserKey, addUserChannel(addedChannel), false);

        return addedChannel;
    };

    return useCallback(addChannel, [axios]);
}

/*
 * useDeleteChannel returns a function that deletes a channel from a user
 */
export function useDeleteChannel() {
    const axios = useHttp();

    const deleteChannel = async ({ channelId }: DeleteChannelBody): Promise<IChannelDto | null> => {
        if (!channelId) {
            return null;
        }

        mutate(UserKey, removeChannel(channelId), false);

        const payload = { data: { channelId } };
        const { data: deletedChannel } = await axios.delete(URL, payload);
        mutate(UserKey);
        return deletedChannel;
    };

    return useCallback(deleteChannel, [axios]);
}

/*
 * useValidateChannelURL tries a basic validation of the youtube channel url
 * in order to provide real time feedback to the user
 */
export function useValidateChannelURL(url: string): boolean {
    const [state, setState] = useState<boolean>(true);
    const ref = useRef(url);

    useEffect(() => {
        ref.current = url;
    });

    const debouncedCheck = _.debounce(() => {
        setState(isYTURL(ref.current));
    }, 500);

    const handleUrlChange = useCallback(debouncedCheck, [debouncedCheck]);

    useEffect(handleUrlChange, [handleUrlChange]);

    return state;
}
